import { useEffect } from "react";
import { useState } from "react";

const listeners = new Set<() => void>();

export function useThemeToggle() {
    const [_, s] = useState(0);

    useEffect(() => {
        if (localStorage.LIGHT) {
            document.getElementsByTagName("html")[0].className = "light";
        } else {
            document.getElementsByTagName("html")[0].className = "dark";
        }
    }, [localStorage.LIGHT]);

    return () => {
        if (localStorage.LIGHT) {
            delete localStorage.LIGHT
        } else {
            localStorage.LIGHT = "yes"
        }
        listeners.forEach(i => i());
        s(i => i + 1);
    }
}

export function useTheme() {
    const [value, setValue] = useState(localStorage.LIGHT);

    useEffect(() => {
        const f = () => {
            setValue(localStorage.LIGHT);
        }
        listeners.add(f);
        return () => { listeners.delete(f) };
    }, [])

    return !!value;
}