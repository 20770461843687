import { Button, Form, Modal, InputGroup } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";
import { Config } from "../../../Types/Settings";
import useData from "../../../Hooks/useData";
import { MinimalDept } from "../../../Types/Management";
import {VatRateSelector, GetDefaultVatRate} from "./VatRateSelector";
import DeptJobSelect from "../../Management/Editors/DeptJobSelect";

interface CreateProps {
    onHide(): void;
    onAdd(
        jobCode: string,
        description: string,
        notes: string,
        perItem: number,
        quantity: number,
        vatCode: string,
        vat: number,
        deptId: number | null
    ): void;
    invoiceNumber?: number;
}

export default function AddManual({ onHide, onAdd, invoiceNumber }: CreateProps) {
    const jobCode = useRef("");
    const description = useRef("");
    const perItem = useRef("");
    const quantity = useRef("");
    const notes = useRef("");
    const [dept, setDept] = useState<string | null>("");
    const config = useData<Config>("/API/Config");
    const vatCode = useRef("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const defaultVatRate = GetDefaultVatRate(config?.data);
        vatCode.current = defaultVatRate?.parameterKey || "";
    }, [config?.data]);


    const depts = useData<MinimalDept[]>(
        "/API/Invoicing/Invoices/{invoiceNumber}/Depts",
        { invoiceNumber },
        { enabled: !!invoiceNumber }
    );

    const selectedDept = dept ? Number(dept) : depts.data?.[0]?.deptId ?? null;

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Add Manual Item</Modal.Title>
            </Modal.Header>
            <Form.Group controlId="manualItemDepartment" className="m-2">
                <Form.Label>Department</Form.Label>
                <Form.Control
                    value={dept ?? undefined}
                    as="select"
                    onChange={ev => {jobCode.current = ""; perItem.current = ""; setDept(ev.target.value)}}>
                    {depts.data?.map(i => (
                        <option key={i.deptId} value={i.deptId}>{i.clientName} ({i.siteName}) {i.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="manualItemDescription" className="m-2">
                <Form.Label>Job Code</Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control
                        disabled={true}
                        defaultValue={ jobCode.current ? `${jobCode.current}: ${description.current}` : ""}
                    />
                    <InputGroup.Append>
                        <Button onClick={() => { setShowModal(true); }}>Select</Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="manualItemNotes" className="m-2">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                    type="text"
                    maxLength={255}
                    onChange={ev => (notes.current = ev.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="manualItemQuantity" className="m-2">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                    required={true}
                    type="number"
                    defaultValue={quantity.current}
                    onChange={ev => (quantity.current = ev.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="manualItemPerItem" className="m-2">
                <Form.Label>£ per Item</Form.Label>
                <Form.Control
                    required={true}
                    type="number"
                    defaultValue={perItem.current}
                    onChange={ev => (perItem.current = ev.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="manualItemVATRate" className="m-2">
                <Form.Label>VAT Rate</Form.Label>
                <VatRateSelector
                    value={vatCode.current}
                    config={config.data}
                    onChange={ev => (vatCode.current = ev)}/>           
            </Form.Group>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        onHide();
                        jobCode.current = "";
                        description.current = "";
                        notes.current = "";
                        quantity.current = "";
                        perItem.current = "";
                    }}
                    variant="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (!jobCode.current) {
                            alert("You must select a Job Code");
                            return;
                        }
                        if (!quantity.current) {
                            alert("You must enter a Quantity");
                            return;
                        }
                        if (!perItem.current) {
                            alert("You must enter a £ per Item");
                            return;
                        }
                        const per = Number(perItem.current);
                        const quan = Number(quantity.current);
                        onAdd(
                            jobCode.current,
                            description.current,
                            notes.current,
                            per,
                            quan,
                            vatCode.current,
                            ((config.data?.vat.find(i => i.parameterKey === vatCode.current)
                                ?.valueNumeric ?? 0) *
                                per) /
                                100,
                            selectedDept
                        );
                        setDept(null);
                        jobCode.current = "";
                        description.current = "";
                        notes.current = "";
                        quantity.current = "";
                        perItem.current = "";
                        vatCode.current = "";
                    }}>
                    Add Manual Item
                </Button>
            </Modal.Footer>
<Modal show={showModal} onHide={() => setShowModal(false)}>
    <DeptJobSelect deptId={selectedDept ?? 0}  onSelect={(newJobCode, newDescription, newPrice) => {
        if (newJobCode) {
            jobCode.current = newJobCode;
            description.current = newDescription || "";
            if (newPrice) {
                perItem.current = newPrice.toString();
            } else {
                perItem.current = "";
            }
            
        }
        setShowModal(false);
    }} existing={new Set([])} />
</Modal>
        </>
    );
}
