import { Form, Modal, Table } from "react-bootstrap";
import useData from "../../../Hooks/useData";
import { useMemo } from "react";
import useSearch from "../../../Hooks/useSearch";
import Fuse from "fuse.js";
import { ChevronRight } from "react-bootstrap-icons";

interface ManualInvoiceDeptGroupSelectProps {
    onSelect(invoiceGroupId?: number, deptId?: number): void;
}

export default function ManualInvoiceDeptGroupSelect({ onSelect }: ManualInvoiceDeptGroupSelectProps) {
    const { data, dataUpdatedAt } = useData<[string, number, number][]>("/API/InvoiceGroupings");
    const [value, search, onValue] = useSearch(200);
    const list = useMemo(() => {
        if (!data) return [];
        if (!search) return data;
        const fuse = new Fuse(data, {
            keys: ["0", "1", "2"],
        });
        return fuse.search(search).map(i => i.item);
    }, [dataUpdatedAt, search]);
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Select a Department/Group for your Invoice</Modal.Title>
            </Modal.Header>
            <Form.Group controlId="jobSelectSearch" className="ml-2 mr-2">
                <Form.Label>Search</Form.Label>
                <Form.Control
                    value={value}
                    onChange={ev => onValue(ev.target.value)}
                    placeholder="Search by Department/Group Name"
                />
            </Form.Group>
            <Table striped hover>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td style={{ width: 20 }} />
                    </tr>
                </thead>
                <tbody>
                    {list.slice(0, 30).map(i => (
                        <tr
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                onSelect(i[1] ? i[1] : undefined, i[2] ? i[2] : undefined)
                            }>
                            <td>{i[0]}</td>
                            <td style={{ width: 20 }}>
                                <ChevronRight />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {list.length > 30 && <h6 className="text-center">Too many results to display</h6>}
        </>
    );
}
