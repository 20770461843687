import {useState, useEffect} from "react";
import {Button, ListGroup, Modal, Tab, Tabs} from "react-bootstrap";
import useData from "../../Hooks/useData";
import {useParams} from "react-router-dom";
import {Dept, InvoiceGroup, MinimalSite} from "../../Types/Management";
import DeptSelect from "./Editors/DeptSelect";
import useDataMutation from "../../Hooks/useDataMutation";
import {Trash} from "react-bootstrap-icons";
import useDataForm from "../../Hooks/useDataForm";
import ManagementGroupEditor from "./Editors/Group";
import useAuth from "../../Hooks/useAuth";
import { Prompt } from 'react-router';

interface ManagementPageParams {
  client: string;
  group: string;
}

export default function ManagementGroup() {
  const auth = useAuth();
  const params = useParams<ManagementPageParams>();
  const {data: sites} = useData<MinimalSite[]>("/API/Clients/{client}/Sites", {client: params.client});
  const [group, groupForm] = useDataForm<InvoiceGroup>("/API/Clients/{client}/Groups/{group}", {
    client: params.client,
    group: params.group
  });
  const groupMutation = useDataMutation<{ success: boolean }, InvoiceGroup>("/API/Clients/{client}/Groups/{group}", {
    client: params.client,
    group: params.group
  });
  const depts = useData<Dept[]>("/API/Clients/{client}/Groups/{group}/Depts", {
    client: params.client,
    group: params.group
  });
  const deptsAddMutation = useDataMutation<{ success: boolean }, { deptId: number }>("/API/Clients/{client}/Groups/{group}/Depts/Add", {
    client: params.client,
    group: params.group
  });
  const deptsRemoveMutation = useDataMutation<{ success: boolean }, { deptId: number }>("/API/Clients/{client}/Groups/{group}/Depts/Remove", {
    client: params.client,
    group: params.group
  });
  const [showModal, setShowModal] = useState(false);
  useEffect(()=>{groupForm.trigger();}, []);
  const hasErrors = !groupForm.formState.isValid;
  const hasChanges = groupForm.formState.isDirty;
  return (
      <>
<Prompt
    when={hasChanges}
    message='You have unsaved changes, are you sure you want to leave?'
/>
    <div className="mt-2">
      <div className="d-flex flex-row align-items-center"><h4
        className="flex-grow-1">{group.data?.invoiceGroupName ?? "Loading..."}</h4>
        {auth.isAdmin && <>
          {hasErrors && <span className="text-danger mr-2">Please correct errors before saving</span>}
          <Button
            onClick={() => {
              groupForm.handleSubmit(() => {
                if (groupForm.formState.isDirty)
                groupMutation.mutate(groupForm.getValues());
              })();             
            }}
            disabled={
              !hasChanges
            }
          >
            Save
          </Button>
          </> 
          }
      </div>
      <Tabs id="managementTabs">
        <Tab eventKey="details" title="Details">
          <ManagementGroupEditor form={groupForm}/>
        </Tab>
        <Tab eventKey="depts" title="Departments">
          <ListGroup className="pt-2">
            {depts.data?.map(dept => (
              <ListGroup.Item key={dept.deptId}>
                  {dept.clientName} ({dept.siteName}) {dept.name}
                {auth.isAdmin && <Button className="float-right" variant="outline-danger" onClick={() => {
                  deptsRemoveMutation.mutate({deptId: dept.deptId});
                }}><Trash/></Button>}
              </ListGroup.Item>
            ))}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <DeptSelect onSelect={async (deptId) => {
                await deptsAddMutation.mutate({deptId});
                //setShowModal(false);
              }}/>
            </Modal>
            {auth.isAdmin && <ListGroup.Item className="p-0 d-flex flex-row">
                <Button className="ml-2 mr-2 mb-2 flex-grow-1" onClick={() => setShowModal(true)}>Add
                    Department</Button>
            </ListGroup.Item>}
          </ListGroup>
        </Tab>
      </Tabs>
    </div></>
  );
}
