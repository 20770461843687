import { useMemo, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import useData from "../../../Hooks/useData";
import useDataMutation from "../../../Hooks/useDataMutation";
import { Invoice, InvoiceItemWithDeptName } from "../../../Types/Invoicing";
import { formatNumber } from "../../../Utils/Format";
import AddManual from "./AddManualItem";
import PDFModal from "../../../Components/PDF";
import { useRef } from "react";
import EditInvoiceItem from "./EditInvoiceItem";
import ResendEmails from "./ResendEmails";
import { saveWorkbook, useChartColours } from "../Reports/Utils";
import ExcelJS from "exceljs";

function applyNumeric(row: ExcelJS.Row, value: number, key: string) {
  row.getCell(key).value = value;
  row.getCell(key).numFmt =
    '_-[$£-en-GB]* #,##0.00_-;-[$£-en-GB]* #,##0.00_-;_-[$£-en-GB]* "-"??_-;_-@_-';
}

function generateSpreadsheet(
  data: InvoiceItemWithDeptName[] | undefined,
  invoice: Invoice
) {
  const workbook = new ExcelJS.Workbook();
  workbook.calcProperties.fullCalcOnLoad = true;

  const sheet = workbook.addWorksheet("Invoice Export", {
    views: [{ state: "frozen", ySplit: 1 }],
  });

  sheet.columns = [
    { header: "ID", key: "id", width: 4 },
    { header: "Client", key: "client", width: 40 },
    { header: "Site", key: "site", width: 40 },
    { header: "Department", key: "department", width: 40 },
    { header: "Job Code", key: "jobCode", width: 40 },
    { header: "Description", key: "description", width: 40 },
    { header: "Notes", key: "notes", width: 40 },
    { header: "Per Item", key: "perItem", width: 15 },
    { header: "Quantity", key: "quantity", width: 15 },
    { header: "Net Total", key: "netTotal", width: 15 },
    { header: "VAT", key: "vat", width: 15 },
    { header: "Type", key: "type", width: 15 },
  ];

  const invoiceHeader = sheet.getRow(1);
  

  const header = sheet.getRow(3);
  let index = 0;
  for (const col of [
    "ID",
    "Client",
    "Site",
    "Department",
    "Job Code",
    "Description",
    "Notes",
    "Per Item",
    "Quantity",
    "Net Total",
    "VAT",
    "Type",
  ]) {
    index++;
    invoiceHeader.getCell(index).value = "";
    header.getCell(index).value = col;
    header.getCell(index).style = { font: { bold: true } };
    header.getCell(index).border = { bottom: { style: "thick" } };
  }
  
  sheet.mergeCells('A1:F1');
  invoiceHeader.getCell(
    1
  ).value = `Invoice ${invoice.invoiceNumber} for ${invoice.name} created for run ${invoice.invoiceRunId} exported at ${new Date()}`;

  if (data) {
    for (let item = 0; item < data.length; item++) {
      const row = sheet.getRow(item + 4);
      row.getCell("id").value = data[item].invoiceItemNumber;
      row.getCell("client").value = data[item].clientName;
      row.getCell("site").value = data[item].siteName;
      row.getCell("department").value = data[item].deptName;
      row.getCell("jobCode").value = data[item].jobCode;
      row.getCell("description").value = data[item].description;
      row.getCell("notes").value = data[item].notes;
      applyNumeric(row, data[item].amountEach, "perItem");
      applyNumeric(row, data[item].quantity, "quantity");
      applyNumeric(
        row,
        data[item].amountEach * data[item].quantity,
        "netTotal"
      );
      applyNumeric(row, data[item].vatEach * data[item].quantity, "vat");
      row.getCell("type").value = data[item].itemType;
    }
  }

  saveWorkbook(
    `Invoice Run ${invoice.invoiceRunId} - Invoice ${invoice.invoiceNumber}.xlsx`,
    workbook
  );
}

export default function Invoices({
  invoices,
  readOnly,
}: {
  invoices: Invoice[];
  readOnly: boolean;
}) {
  const [open, setOpen] = useState(0);
  const [edit, setEdit] = useState(0);
  const [addManual, setAddManual] = useState(false);
  const [resend, setResend] = useState(false);
  const [viewPDF, setViewPDF] = useState<string | null>(null);
  const notesRef = useRef<string | null>(null);
  const invoice = invoices.find((i) => i.invoiceNumber === open);
  useMemo(() => {
    notesRef.current = invoice?.notes ?? null;
  }, [open]);
  const items = useData<InvoiceItemWithDeptName[]>(
    "/API/Invoicing/Invoices/{invoiceNumber}/Items",
    { invoiceNumber: open },
    { enabled: !!invoice }
  );
  const addItem = useDataMutation(
    "/API/Invoicing/Invoices/{invoiceNumber}/Items/Add",
    {
      invoiceNumber: open,
    },
    { enabled: !!invoice },
    [
      { queryKey: ["/API/Invoicing/Invoices/{invoiceNumber}/Items"] },
      { queryKey: ["/API/Invoicing/Runs/{run}"] },
    ]
  );
  const updateNotes = useDataMutation(
    "/API/Invoicing/Invoices/{invoiceNumber}/UpdateNotes",
    {
      invoiceNumber: open,
    },
    { enabled: !!invoice },
    [{ queryKey: ["/API/Invoicing/Runs/{run}"] }]
  );
  const resendMutation = useDataMutation(
    "/API/Invoicing/Invoices/{invoiceNumber}/ResendEmails",
    {
      invoiceNumber: open,
    },
    { enabled: !!invoice },
    []
  );
  const removeItem = useDataMutation(
    "/API/Invoicing/Invoices/{invoiceNumber}/Items/{invoiceItem}/Remove",
    {
      invoiceNumber: open,
    },
    { enabled: !!invoice },
    [
      { queryKey: ["/API/Invoicing/Invoices/{invoiceNumber}/Items"] },
      { queryKey: ["/API/Invoicing/Runs/{run}"] },
    ]
  );
  const removeInvoice = useDataMutation(
    "/API/Invoicing/Invoices/{invoiceNumber}/Remove",
    {
      invoiceNumber: open,
    },
    { enabled: !!invoice },
    [{ queryKey: ["/API/Invoicing/Runs/{run}"] }]
  );
  const editItem = useDataMutation(
    "/API/Invoicing/Invoices/{invoiceNumber}/Items/{invoiceItem}/Edit",
    {
      invoiceNumber: open,
    },
    { enabled: !!invoice },
    [
      { queryKey: ["/API/Invoicing/Invoices/{invoiceNumber}/Items"] },
      { queryKey: ["/API/Invoicing/Runs/{run}"] },
    ]
  );
  return (
    <>
      <Table className="m-0" size="sm" striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Invoice Group/Dept</th>
            <th>Code</th>
            <th>Recipient</th>
            <th className="text-right">Net</th>
            <th className="text-right">VAT</th>
            <th className="text-right">Gross</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr
              className={invoice.net < 0 ? "row-credit" : ""}
              key={invoice.invoiceNumber}
            >
              <td>{invoice.invoiceNumber}</td>
              <td>
                <a href={invoice.managementUrl}>{invoice.name}</a>
              </td>
              <td>
                {(invoice.accountCodeToAllocateTo ?? "").length === 0 ? (
                  <span className={"text-danger"}>Missing accounts code</span>
                ) : (
                  <>{invoice.accountCodeToAllocateTo}</>
                )}
              </td>
              <td className="invoice-list-recipients">
                {(invoice.emailAddressesToSendTo ?? "").length === 0 ? (
                  <span className={"text-danger"}>Missing email address</span>
                ) : (
                  <>{invoice.emailAddressesToSendTo}</>
                )}
              </td>
              {invoice.itemCount > 0 ? (
                <>
                  <td className="text-right">
                    {formatNumber(invoice.net, "GBP")}
                  </td>
                  <td className="text-right">
                    {formatNumber(invoice.vat, "GBP")}
                  </td>
                  <td className="text-right">
                    {formatNumber(invoice.gross, "GBP")}
                  </td>
                </>
              ) : (
                <td className="text-warning text-center" colSpan={3}>
                  This invoice has no items
                </td>
              )}
              <td>
                <Button
                  size="sm"
                  onClick={() => setOpen(invoice.invoiceNumber)}
                >
                  View/Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        show={!!invoice}
        onHide={() => setOpen(0)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {invoice && (
          <>
            <Modal.Header
              closeButton
              className="d-flex flex-row justify-content-start"
            >
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ flexGrow: 1 }}
              >
                Invoice {invoice.invoiceNumber} - {invoice.name}
              </Modal.Title>
              <Button
                disabled={
                  !items.data?.some(
                    (i) => i.quantity * (i.amountEach + i.vatEach) !== 0
                  )
                }
                className="mr-2"
                onClick={() => generateSpreadsheet(items.data, invoice)}
              >
                Excel Export
              </Button>
              <Button
                disabled={
                  !items.data?.some(
                    (i) => i.quantity * (i.amountEach + i.vatEach) !== 0
                  )
                }
                className="mr-2"
                onClick={() => setViewPDF("File")}
              >
                View File
              </Button>
              {invoice.fileContent && (
                <Button className="mr-2" onClick={() => setResend(true)}>
                  Resend Emails
                </Button>
              )}
              <Button
                  className="mr-2"
                  onClick={() => setViewPDF("BackingSheet")}
                >
                  View Backing Sheet
                </Button>
            </Modal.Header>
            <Table className="m-0" size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Department</th>
                  <th>Job Code</th>
                  <th>Description</th>
                  <th>Notes</th>
                  <th className="text-right">Per Item</th>
                  <th className="text-right">Quantity</th>
                  <th className="text-right">Net Total</th>
                  <th className="text-right">VAT</th>
                  <th>Type</th>
                  {!readOnly && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {items.data ? (
                  items.data.length === 0 ? (
                    <tr>
                      <td
                        colSpan={readOnly ? 8 : 9}
                        className="p-2 text-center"
                      >
                        No Items for this invoice
                        {!readOnly && (
                          <Button
                            className="ml-4"
                            variant="danger"
                            onClick={() => {
                              removeInvoice.mutate({
                                invoiceNumber: invoice.invoiceNumber,
                              });
                              setOpen(0);
                            }}
                          >
                            Delete invoice
                          </Button>
                        )}
                      </td>
                    </tr>
                  ) : (
                    items.data.map((item) => (
                      <tr key={item.invoiceItemNumber}>
                        <td>{item.invoiceItemNumber}</td>
                        <td>
                          {item.clientName} ({item.siteName}) {item.deptName}
                        </td>
                        <td>{item.jobCode}</td>
                        <td>{item.description}</td>
                        <td>{item.notes}</td>
                        <td className="text-right">
                          {formatNumber(item.amountEach, "GBP")}
                        </td>
                        <td className="text-right">{item.quantity}</td>
                        <td className="text-right">
                          {formatNumber(item.amountEach * item.quantity, "GBP")}
                        </td>
                        <td className="text-right">
                          {formatNumber(item.vatEach * item.quantity, "GBP")}
                        </td>
                        <td>{item.itemType}</td>
                        {!readOnly && (
                          <td>
                            <Button
                              disabled={item.itemType === "DOC"}
                              size="sm"
                              className="mr-2 my-1"
                              onClick={() => setEdit(item.invoiceItemNumber)}
                            >
                              Edit
                            </Button>
                            <Button
                              disabled={item.itemType === "DOC"}
                              size="sm"
                              variant="danger"
                              className="my-1"
                              onClick={() => {
                                removeItem.mutate({
                                  invoiceItem: item.invoiceItemNumber,
                                });
                              }}
                            >
                              Remove
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))
                  )
                ) : (
                  <tr>
                    <td colSpan={readOnly ? 8 : 9} className="p-2 text-center">
                      Loading...
                    </td>
                  </tr>
                )}
                {!readOnly && (
                  <tr>
                    <td className="text-right" colSpan={readOnly ? 8 : 9}>
                      <Button onClick={() => setAddManual(true)}>
                        Add Manual Item
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="p-2 pt-4 d-flex flex-column">
              <h5>Notes</h5>
              <Form.Control
                key={`notes_${open}`}
                disabled={readOnly}
                as="textarea"
                rows={3}
                defaultValue={notesRef.current ?? ""}
                onChange={(e) => {
                  notesRef.current = e.target.value;
                }}
              />
              {!readOnly && (
                <Button
                  onClick={() => {
                    updateNotes.mutate({ notes: notesRef.current ?? null });
                  }}
                  className="mt-2 align-self-end"
                >
                  Save Notes
                </Button>
              )}
            </div>
            {/* {!readOnly && <Modal.Footer></Modal.Footer>} */}
          </>
        )}
      </Modal>
      <Modal show={addManual} onHide={() => setAddManual(false)} centered>
        <AddManual
          invoiceNumber={open}
          onHide={() => setAddManual(false)}
          onAdd={(
            jobCode,
            description,
            notes,
            perItem,
            quantity,
            vatCode,
            vatEach,
            deptId
          ) => {
            addItem
              .mutateAsync({
                invoiceNumber: open,
                jobCode,
                notes,
                description,
                amountEach: perItem,
                vatCode,
                vatEach,
                quantity,
                deptId,
              })
              .then(() => setAddManual(false));
          }}
        />
      </Modal>
      <Modal show={resend} onHide={() => setResend(false)} centered>
        <ResendEmails
          invoice={invoice}
          onHide={() => setAddManual(false)}
          onAdd={(emails) => {
            resendMutation
              .mutateAsync({
                invoiceNumber: open,
                emails,
              })
              .then(() => setResend(false));
          }}
        />
      </Modal>
      <Modal show={!!edit} onHide={() => setEdit(0)} centered>
        <EditInvoiceItem
          invoiceItem={items.data?.find((i) => i.invoiceItemNumber === edit)}
          onHide={() => setEdit(0)}
          onAdd={(notes, quantity, vatCode, vatEach) => {
            editItem
              .mutateAsync({
                invoiceItem: edit,
                invoiceItemNumber: edit,
                notes,
                quantity,
                vatCode,
                vatEach,
              })
              .then(() => setEdit(0));
          }}
        />
      </Modal>
      <PDFModal
        url={
          viewPDF
            ? `/API/Invoicing/Invoices/${invoice?.invoiceNumber}/${viewPDF}.pdf`
            : null
        }
        title={`Invoice ${invoice?.invoiceNumber} - ${invoice?.name}`}
        close={() => setViewPDF(null)}
      />
    </>
  );
}
