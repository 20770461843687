import {Form, Table} from "react-bootstrap";
import { SettingsData } from "../../Types/Settings";

export interface SystemInfoProps {
  data: SettingsData;
}

export default function SystemInfo({data}: SystemInfoProps) {
  return <>
    <Form>
      <legend>System Values</legend>
      <Table striped bordered size="sm" hover className="m-0">
        <thead>
        <tr>
          <th>Key</th>
          <th>Description</th>
          <th>Value</th>
        </tr>
        </thead>
        <tbody>
        {data?.system.map(i => (
          <tr key={i.parameterKey}>
            <td>
              {i.parameterKey}
            </td>
            <td>
              {i.description}
            </td>
            <td>
              {i.valueString ?? i.valueNumeric ?? "N/A"}
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </Form>

  </>
}