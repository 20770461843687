import { useMemo } from "react";
import { Button, Card, Row, Table } from "react-bootstrap";
import { Table as RBITable } from "react-bootstrap-icons";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import useData from "../../../Hooks/useData";

import { InvoicedByDept } from "../../../Types/Reports";
import { formatNumber } from "../../../Utils/Format";
import { saveWorkbook, useChartColours } from "./Utils";
import ExcelJS from "exceljs";

const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
};

function generateSpreadsheet(data: InvoicedByDept[], runID: string) {
    const workbook = new ExcelJS.Workbook();
    workbook.calcProperties.fullCalcOnLoad = true;

    const sheet = workbook.addWorksheet("Invoiced Per Dept", {
        views: [{ state: "frozen", ySplit: 1 }],
    });

    sheet.columns = [
        { header: "ID", key: "deptId", width: 4 },
        { header: "Department Name", key: "deptName", width: 40 },
        { header: "Net Total", key: "invoiced", width: 15 },
    ];

    const header = sheet.getRow(1);
    let index = 0;
    for (const col of ["ID", "Department Name", "Net Total"]) {
        index++;
        header.getCell(index).value = col;
        header.getCell(index).style = { font: { bold: true } };
        header.getCell(index).border = { bottom: { style: "thick" } };
    }

    for (let item = 0; item < data.length; item++) {
        const row = sheet.getRow(item + 2);
        row.getCell("deptId").value = data[item].deptId;
        row.getCell("deptName").value = data[item].deptName;
        row.getCell("invoiced").value = data[item].invoiced;
        row.getCell("invoiced").numFmt =
            '_-[$£-en-GB]* #,##0.00_-;-[$£-en-GB]* #,##0.00_-;_-[$£-en-GB]* "-"??_-;_-@_-';
    }

    sheet.getRow(data.length + 2).getCell(3).style = { font: { bold: true } };
    sheet.getRow(data.length + 2).getCell(3).value = {
        formula: `=SUM(C2:C${data.length + 1})`,
        result: data.reduce((a, b) => a + b.invoiced, 0),
        sharedFormula: `=SUM(C2:C${data.length + 1})`,
        date1904: false,
    };
    sheet.getRow(data.length + 2).getCell(3).numFmt =
        '_-[$£-en-GB]* #,##0.00_-;-[$£-en-GB]* #,##0.00_-;_-[$£-en-GB]* "-"??_-;_-@_-';
    sheet.getRow(data.length + 2).getCell(3).border = {
        top: { style: "thick" },
    };

    saveWorkbook(`Invoice Run ${runID} - Invoiced Per Dept.xlsx`, workbook);
}

export default function InvoicedPerDept() {
    const { run: runID } = useParams<{ run: string }>();
    const invoicedByDept = useData<InvoicedByDept[]>("/API/Reports/Runs/{run}/InvoicedPerDept", {
        run: runID,
    });
    const chartColours = useChartColours(invoicedByDept.data?.length ?? 0);
    const data = useMemo(() => {
        if (invoicedByDept.data) {
            return {
                labels: invoicedByDept.data.map(i => `${i.deptName ?? "Unassigned"} (£)`),
                datasets: [
                    {
                        label: "Net Total",
                        data: invoicedByDept.data.map(i => i.invoiced),
                        ...chartColours,
                        borderWidth: 1,
                    },
                ],
            };
        }
        return null;
    }, [invoicedByDept.dataUpdatedAt, chartColours]);

    if (!invoicedByDept.data) {
        return <h6>Loading...</h6>;
    }
    var nullAny: any = null;
    return (
        <div>
            <div className="d-flex flex-row align-items-center pt-2 pb-2">
                <h3 className="flex-grow-1">Invoiced Per Dept</h3>
                <Button
                    onClick={() => {
                        generateSpreadsheet(invoicedByDept.data ?? [], runID);
                    }}
                    variant="success">
                    Export to Excel <RBITable />
                </Button>
            </div>
            <Card className="mb-2">
                <Bar type="bar" data={data} height={500} width={nullAny} options={chartOptions} />
            </Card>
            <Table size="sm" bordered>
                <thead>
                    <tr className="bg-secondary">
                        <th>ID</th>
                        <th>Department Name</th>
                        <th>Net Total</th>
                    </tr>
                </thead>
                <tbody>
                    {invoicedByDept.data.map(i => (
                        <tr key={i.deptId}>
                            <td>{i.deptId ?? "N/A"}</td>
                            <td className="font-weight-bold">{i.deptName ?? "Unassigned"}</td>
                            <td className="text-right text-monospace">
                                {formatNumber(i.invoiced, "GBP")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
