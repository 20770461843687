import { Button, Form, Modal } from "react-bootstrap";
import { Invoice } from "../../../Types/Invoicing";
import { useState } from "react";
import { useEffect } from "react";

interface ResendEmailsProps {
    onHide(): void;
    onAdd(emails: string): void;
    invoice?: Invoice;
}

export default function ResendEmails({ onHide, onAdd, invoice }: ResendEmailsProps) {
    const [emails, setEmails] = useState("");
    useEffect(() => {
        setEmails(invoice?.emailAddressesSentTo ?? "");
    }, [invoice]);
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Resend Emails</Modal.Title>
            </Modal.Header>
            <Form.Group controlId="resentEmailsInput" className="m-2">
                <Form.Label>Email Addresses (Comma Separated)</Form.Label>
                <Form.Control value={emails} onChange={ev => setEmails(ev.target.value)} />
            </Form.Group>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        onHide();
                    }}
                    variant="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onAdd(emails);
                    }}>
                    Resend Emails
                </Button>
            </Modal.Footer>
        </>
    );
}
