import {useMutation, useQuery, useQueryClient, UseQueryOptions} from "react-query";
import { QueryFilters } from "react-query/types/core/utils";

function resolvePath(path: string, parameters: {[key: string]: string|number|null|undefined}): string {
    return path.replace(/\{([^}]+)\}/g, function (a, b) {
        const param = parameters[b];
        if (param === undefined || param === null) return a;
        return encodeURIComponent(param)
    });
}

export default function useDataMutation<T, D, E = unknown>(
  path: string,
  parameters: { [key: string]: string | number | null | undefined } = {},
  options?: UseQueryOptions<T>,
  refreshFilters?: QueryFilters[]
) {
  const resolvedPath = resolvePath(path, parameters);
  const queryClient = useQueryClient();
  return useMutation<T, E, D>(
    [resolvedPath],
    async d => {
      const response = await fetch(resolvePath(path, { ...parameters, ...d }), {
        method: "post",
        body: JSON.stringify({
          ...parameters,
          ...d,
        }),
        headers: new Headers({ "Content-Type": "application/json" }),
      });
      if (!response.ok) {
        throw await response.json();
      }
    for (let filter of refreshFilters ?? [undefined]) {
      await queryClient.invalidateQueries(filter);
    }
      return await response.json();
    },
    options
  );
}
