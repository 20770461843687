import { Button, Form, Modal } from "react-bootstrap";
import { useRef } from "react";

interface CreateProps {
  onHide(): void;
  onCreate(name: string): void;
  type: string;
}

export default function Create({ onHide, onCreate, type }: CreateProps) {
  const ref = useRef("");
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add a {type}</Modal.Title>
      </Modal.Header>
      <Form.Group controlId="jobSelectSearch" className="m-2">
        <Form.Label>{type} Name</Form.Label>
        <Form.Control
          defaultValue={ref.current}
          onChange={(ev) => (ref.current = ev.target.value)}
        />
      </Form.Group>
      <Modal.Footer>
        <Button onClick={() => onHide()} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!ref.current) {
              alert("You must enter a name.");
              return;
            }
            onCreate(ref.current);
            ref.current = "";
          }}
        >
          Add {type}
        </Button>
      </Modal.Footer>
    </>
  );
}
