import {Button, Tabs, Tab} from "react-bootstrap";
import useDataForm from "../../Hooks/useDataForm";
import useDataMutation from "../../Hooks/useDataMutation";
import {EmailSettings, JobsData, SettingsData, CompanySettings, InvoicingSettings} from "../../Types/Settings";
import useAuth from "../../Hooks/useAuth";
import OperationsEditor from "./Operations";
import VATEditor from "./VAT";
import CompanyEditor from "./Company";
import {useState} from "react";
import EmailEditor from "./Email";
import ManagerEditor from "./Manager";
import SystemInfo from "./System";
import PageLoader from "../../Components/PageLoader";
import InvoicingEditor from "./Invoicing";
import { Prompt } from 'react-router';
import CountryEditor from "./Country";

export default function SettingsPage() {
  const auth = useAuth();
  const [tab, setTab] = useState("company");
  const [jobCodesData, jobCodesForm] = useDataForm<JobsData>("/API/Jobs", {}, {enabled: tab === "jobCodes"});
  const jobCodesMutation = useDataMutation<JobsData, JobsData>("/API/Jobs");

  const [settingsData, settingsForm] = useDataForm<SettingsData>("/API/Settings", {}, {enabled: tab === "vat" || tab === "manager" || tab === "system"});
  const settingsMutation = useDataMutation<SettingsData, SettingsData>("/API/Settings");

  const [emailData, emailForm] = useDataForm<EmailSettings>("/API/Settings/Email", {}, {enabled: tab === "email"});
  const emailMutation = useDataMutation<EmailSettings, EmailSettings>("/API/Settings/Email");

  const [companyData, companyForm] = useDataForm<CompanySettings>("/API/Settings/Company", {}, {enabled: tab === "company"});
  const companyMutation = useDataMutation<CompanySettings, CompanySettings>("/API/Settings/Company");

  const [invoicingData, invoicingForm] = useDataForm<InvoicingSettings>("/API/Settings/Invoicing", {}, {enabled: tab === "invoicing"});
  const invoicingMutation = useDataMutation<InvoicingSettings, InvoicingSettings>("/API/Settings/Invoicing");
  const hasChanges = jobCodesForm.formState.isDirty ||
      settingsForm.formState.isDirty ||
      emailForm.formState.isDirty ||
      companyForm.formState.isDirty ||
      invoicingForm.formState.isDirty;

  return (
      <>
<Prompt
    when={hasChanges}
    message='You have unsaved changes, are you sure you want to leave?'
/>
      <div className="mt-2">
          <div className="d-flex flex-row align-items-center">
              <h4 className="flex-grow-1">System Settings</h4>
              {auth.isAdmin && (
                  <Button
                      onClick={() => {
                          if (jobCodesForm.formState.isDirty)
                              jobCodesMutation.mutate(jobCodesForm.getValues());
                          if (settingsForm.formState.isDirty)
                              settingsMutation.mutate(settingsForm.getValues());
                          if (emailForm.formState.isDirty)
                              emailMutation.mutate(emailForm.getValues());
                          if (companyForm.formState.isDirty)
                              companyMutation.mutate(companyForm.getValues());
                          if (invoicingForm.formState.isDirty)
                              invoicingMutation.mutate(invoicingForm.getValues());
                      }}
                      disabled={!hasChanges}>
                      Save
                  </Button>
              )}
          </div>
          <Tabs
              defaultActiveKey="general"
              id="settingsTabs"
              activeKey={tab}
              onSelect={v => setTab(v ?? "general")}>
              <Tab eventKey="company" title="Company" className="p-2">
                  {companyData.data ? <CompanyEditor form={companyForm} /> : <PageLoader />}
              </Tab>
              <Tab eventKey="vat" title="VAT Rates">
                  {settingsData.data ? <VATEditor form={settingsForm} /> : <PageLoader />}
              </Tab>
              <Tab eventKey="manager" title="Managers">
                  {settingsData.data ? <ManagerEditor form={settingsForm} /> : <PageLoader />}
              </Tab>
            <Tab eventKey="country" title="Countries">
              {settingsData.data ? <CountryEditor form={settingsForm} /> : <PageLoader />}
            </Tab>
              <Tab eventKey="jobCodes" title="Job Codes">
                  {jobCodesData.data ? <OperationsEditor form={jobCodesForm} /> : <PageLoader />}
              </Tab>
              <Tab eventKey="email" title="Email" className="p-2">
                  {emailData.data ? <EmailEditor form={emailForm} /> : <PageLoader />}
              </Tab>
              <Tab eventKey="invoicing" title="Invoicing" className="p-2">
                  {invoicingData.data ? <InvoicingEditor form={invoicingForm} /> : <PageLoader />}
              </Tab>
              <Tab eventKey="system" title="System Info" className="p-2">
                  {settingsData.data ? <SystemInfo data={settingsData.data} /> : <PageLoader />}
              </Tab>
          </Tabs>
      </div></>
  );
}
