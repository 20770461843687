import {useQuery, UseQueryOptions} from "react-query";

function resolvePath(path: string, parameters: {[key: string]: string|number|null|undefined}): string {
    return path.replace(/\{([^}]+)\}/g, function (a, b) {
        const param = parameters[b];
        if (param === undefined || param === null) return a;
        return encodeURIComponent(param)
    });
}

export default function useData<T>(path: string, parameters: {[key: string]: string|number|null|undefined} = {}, options?: UseQueryOptions<T>) {
    const resolvedPath = resolvePath(path, parameters);
    return useQuery<T>([path, resolvedPath], async () => {
        const response = await fetch(resolvedPath);
        if(!response.ok) {
            throw new Error(response.statusText)
        }
        return await response.json();
    }, options)
}
