import {useController, UseControllerProps} from "react-hook-form";
import {Form} from "react-bootstrap";
import {ReactNode} from "react";

interface SelectProps {
    id?: string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    children?: ReactNode;
}

export default function ControlledSelect<TFieldValues>(props: Omit<UseControllerProps<TFieldValues>, "defaultValue"> & SelectProps & {defaultValue?: any}) {
    const controller = useController(props);
    
    // Workaround for "<Value> as <Type>" syntax not working
    const value: any = controller.field.value;
    
    return <Form.Control as="select" placeholder={props.placeholder} disabled={props.disabled} id={props.id} className={props.className} 
                         value={value} onChange={controller.field.onChange} children={props.children} />
}