import { Button, Table } from "react-bootstrap";
import { Table as RBITable } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import useData from "../../../Hooks/useData";

import { JobCodeByCustomer } from "../../../Types/Reports";
import { formatNumber } from "../../../Utils/Format";
import { saveWorkbook } from "./Utils";
import ExcelJS from "exceljs";


function generateSpreadsheet(data: JobCodeByCustomer[], runID: string) {
    const workbook = new ExcelJS.Workbook();
    workbook.calcProperties.fullCalcOnLoad = true;

    const sheet = workbook.addWorksheet("Job Code by Customer", {
        views: [{ state: "frozen", ySplit: 1 }],
    });

    sheet.columns = [
        { header: "Invoice Number", key: "invoiceNumber", width: 15 },
        { header: "Client", key: "client", width: 30 },
        { header: "Site", key: "site", width: 30 },
        { header: "Department", key: "dept", width: 30 },
        { header: "Job Code", key: "jobCode", width: 8 },
        { header: "Item Type", key: "itemType", width: 8 },
        { header: "Quantity", key: "quantity", width: 8 },
        { header: "Amount Each", key: "amountEach", width: 15 },
        { header: "VAT Each", key: "vatEach", width: 12 },
        { header: "Amount", key: "amount", width: 15 },
        { header: "VAT", key: "vat", width: 15 },
        { header: "Total", key: "total", width: 15 },
    ];

    const header = sheet.getRow(1);
    for (var index = 1; index <= sheet.columns.length; index++) {

        header.getCell(index).value = sheet.columns[index-1].header?.toString() ?? "";
        header.getCell(index).style = { font: { bold: true } };
        header.getCell(index).border = { bottom: { style: "thick" } };
    }

    function writeMoney(cell: ExcelJS.Cell, value: number){
        cell.value = value;
        cell.numFmt =
        '_-[$£-en-GB]* #,##0.00_-;-[$£-en-GB]* #,##0.00_-;_-[$£-en-GB]* "-"??_-;_-@_-';
    }

    for (let item = 0; item < data.length; item++) {
        const row = sheet.getRow(item + 2);        

        row.getCell("invoiceNumber").value = data[item].invoiceNumber;
        row.getCell("client").value = data[item].client;
        row.getCell("site").value = data[item].site;
        row.getCell("dept").value = data[item].department;
        row.getCell("jobCode").value = data[item].jobCode;
        row.getCell("itemType").value = data[item].itemType;
        row.getCell("quantity").value = data[item].quantity;
        writeMoney(row.getCell("amountEach"), data[item].amountEach);
        writeMoney(row.getCell("vatEach"), data[item].vatEach);
        writeMoney(row.getCell("amount"), data[item].amount);
        writeMoney(row.getCell("vat"), data[item].vat);
        writeMoney(row.getCell("total"), data[item].total);
    }

    saveWorkbook(`Invoice Run ${runID} - Job Code per Customer.xlsx`, workbook);
}

export default function JobCodePerCustomer() {
    const { run: runID } = useParams<{ run: string }>();
    const jobCodePerCustomer = useData<JobCodeByCustomer[]>("/API/Reports/Runs/{run}/JobCodePerCustomer", {
        run: runID,
    });

    if (!jobCodePerCustomer.data) {
        return <h6>Loading...</h6>;
    }
    var nullAny: any = null;
    return (
        <div>
            <div className="d-flex flex-row align-items-center pt-2 pb-2">
                <h3 className="flex-grow-1">Job Code by Customer</h3>
                <Button
                    onClick={() => {
                        generateSpreadsheet(jobCodePerCustomer.data ?? [], runID);
                    }}
                    variant="success">
                    Export to Excel <RBITable />
                </Button>
            </div>
            <Table size="sm" bordered>
                <thead>
                    <tr className="bg-secondary">
                        <th>Invoice Number</th>
                        <th>Client</th>
                        <th>Site</th>
                        <th>Department</th>
                        <th>Job Code</th>
                        <th>Item Type</th>
                        <th>Quantity</th>
                        <th>Amount Each</th>
                        <th>VAT Each</th>
                        <th>Amount</th>
                        <th>VAT</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {jobCodePerCustomer.data.map((item, index) => (
                        <tr key={index}>
                            <td className="text-right text-monospace">
                                {item.invoiceNumber}
                            </td>
                            <td>{item.client}</td>
                            <td>{item.site}</td>
                            <td>{item.department}</td>
                            <td>{item.jobCode}</td>
                            <td>{item.itemType}</td>
                            <td className="text-right text-monospace">
                                {formatNumber(item.quantity, "NUM")}
                            </td>
                            <td className="text-right text-monospace">
                                {formatNumber(item.amountEach, "GBP")}
                            </td>
                            <td className="text-right text-monospace">
                                {formatNumber(item.vatEach, "GBP")}
                            </td>
                            <td className="text-right text-monospace">
                                {formatNumber(item.amount, "GBP")}
                            </td>
                            <td className="text-right text-monospace">
                                {formatNumber(item.vat, "GBP")}
                            </td>
                            <td className="text-right text-monospace">
                                {formatNumber(item.total, "GBP")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
