import { useMemo } from "react";
import { Button, Card, Row, Table } from "react-bootstrap";
import { Table as RBITable } from "react-bootstrap-icons";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import useData from "../../../Hooks/useData";

import { CountByJobCodeDelta } from "../../../Types/Reports";
import { formatNumber } from "../../../Utils/Format";
import { saveWorkbook, useChartColours } from "./Utils";
import ExcelJS from "exceljs";

const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
};

function applyNumeric(row: ExcelJS.Row, value: number, key: string) {
    row.getCell(key).value = value;
    row.getCell(key).numFmt =
        '_-[$£-en-GB]* #,##0.00_-;-[$£-en-GB]* #,##0.00_-;_-[$£-en-GB]* "-"??_-;_-@_-';
}

function applyNumericTotal(cell: ExcelJS.Cell, value: number, column: string, dataLength: number) {
    cell.style = { font: { bold: true } };
    cell.value = {
        formula: `=SUM(${column}2:${column}${dataLength + 1})`,
        result: value,
        sharedFormula: `=SUM(${column}2:${column}${dataLength + 1})`,
        date1904: false,
    };
    cell.numFmt =
        '_-[$£-en-GB]* #,##0.00_-;-[$£-en-GB]* #,##0.00_-;_-[$£-en-GB]* "-"??_-;_-@_-';
    cell.border = {
        top: { style: "thick" },
    };
}

function generateSpreadsheet(data: CountByJobCodeDelta[], runID: string) {
    const workbook = new ExcelJS.Workbook();
    workbook.calcProperties.fullCalcOnLoad = true;

    const sheet = workbook.addWorksheet("Count Per Job Code Delta", {
        views: [{ state: "frozen", ySplit: 1 }],
    });

    sheet.columns = [
        { header: "Job Code", key: "jobCode", width: 40 },
        { header: "Total Quantity", key: "totalQuantity", width: 15 },
        { header: "Total Quantity Prev Run", key: "totalQuantity", width: 15 },
        { header: "Delta", key: "delta", width: 15 },
    ];

    const header = sheet.getRow(1);
    let index = 0;
    for (const col of ["Job Code", "Total Quantity", "Total Prev Run", "Delta"]) {
        index++;
        header.getCell(index).value = col;
        header.getCell(index).style = { font: { bold: true } };
        header.getCell(index).border = { bottom: { style: "thick" } };
    }

    for (let item = 0; item < data.length; item++) {
        const row = sheet.getRow(item + 2);
        row.getCell("jobCode").value = data[item].jobCode;

        applyNumeric(row, data[item].quantitySum, "quantitySum");
        applyNumeric(row, data[item].quantitySumLastRun, "quantitySumLastRun");
        applyNumeric(row, data[item].delta, "delta");
    }

    applyNumericTotal(sheet.getRow(data.length + 2).getCell(3), data.reduce((a, b) => a + b.quantitySum, 0), "C", data.length);
    applyNumericTotal(sheet.getRow(data.length + 2).getCell(4), data.reduce((a, b) => a + b.quantitySumLastRun, 0), "D", data.length);
    applyNumericTotal(sheet.getRow(data.length + 2).getCell(5), data.reduce((a, b) => a + b.delta, 0), "E", data.length);

    saveWorkbook(`Invoice Run ${runID} - Counter Per Job Code Delta.xlsx`, workbook);
}

export default function CountPerJobCodeDelta() {
    const { run: runID } = useParams<{ run: string }>();
    const countPerJobCodeDelta = useData<CountByJobCodeDelta[]>("/API/Reports/Runs/{run}/CountPerJobCodeDelta", {
        run: runID,
    });
    const chartColours = useChartColours(countPerJobCodeDelta.data?.length ?? 0);
    const data = useMemo(() => {
        if (countPerJobCodeDelta.data) {
            return {
                labels: countPerJobCodeDelta.data.map(i => i.jobCode ?? "Unassigned"),
                datasets: [
                    {
                        label: "Delta",
                        data: countPerJobCodeDelta.data.map(i => i.delta),
                        ...chartColours,
                        borderWidth: 1,
                    },
                ],
            };
        }
        return null;
    }, [countPerJobCodeDelta.dataUpdatedAt, chartColours]);

    if (!countPerJobCodeDelta.data) {
        return <h6>Loading...</h6>;
    }
    var nullAny: any = null;
    return (
        <div>
            <div className="d-flex flex-row align-items-center pt-2 pb-2">
                <h3 className="flex-grow-1">Count by Job Code (compared to previous finished run)</h3>
                <Button
                    onClick={() => {
                        generateSpreadsheet(countPerJobCodeDelta.data ?? [], runID);
                    }}
                    variant="success">
                    Export to Excel <RBITable />
                </Button>
            </div>
            <Card className="mb-2">
                <Bar type="bar" data={data} height={500} width={nullAny} options={chartOptions} />
            </Card>
            <Table size="sm" bordered>
                <thead>
                    <tr className="bg-secondary">
                        <th>Job Code</th>
                        <th>Total Quantity</th>
                        <th>Total Quantity Prev Run</th>
                        <th>Delta</th>
                    </tr>
                </thead>
                <tbody>
                    {countPerJobCodeDelta.data.map(i => (
                        <tr key={i.jobCode}>
                            <td className="font-weight-bold">{i.jobCode ?? "Unassigned"}</td>
                            <td className="text-right text-monospace">
                                {formatNumber(i.quantitySum, "NUM")}
                            </td>
                            <td className="text-right text-monospace">
                                {formatNumber(i.quantitySumLastRun, "NUM")}
                            </td>
                            <td className="text-right text-monospace">
                                {formatNumber(i.delta, "NUM")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
