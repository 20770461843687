import { Button, Form, Modal } from "react-bootstrap";
import { useRef } from "react";
import { Config } from "../../../Types/Settings";
import useData from "../../../Hooks/useData";
import { MinimalDept } from "../../../Types/Management";
import { InvoiceItem } from "../../../Types/Invoicing";
import { VatRateSelector } from "./VatRateSelector";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";

interface EditInvoiceItemProps {
  onHide(): void;
  onAdd(notes: string, quantity: number, vatRate: string, vat: number): void;
  invoiceItem?: InvoiceItem;
}

export default function EditInvoiceItem({
  onHide,
  onAdd,
  invoiceItem,
}: EditInvoiceItemProps) {
  const [quantity, setQuantity] = useState("");
  const [vatCode, setVatCode] = useState("");
  const [notes, setNotes] = useState("");
  const config = useData<Config>("/API/Config");
  useEffect(() => {
    setNotes(invoiceItem?.notes ?? "");
    setQuantity(invoiceItem?.quantity.toString() ?? "");
    setVatCode(invoiceItem?.vatCode ?? "");
  }, [invoiceItem]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit Item</Modal.Title>
      </Modal.Header>
      <Form.Group controlId="manualItemNotes" className="m-2">
        <Form.Label>Notes</Form.Label>
        {invoiceItem?.itemType === "MAN" ? (
          <Form.Control
            value={notes}
            type="text"
            maxLength={255}
            onChange={(ev) => setNotes(ev.target.value)}
          />
        ) : (
          <small className="form-text text-muted">
            Notes are only available for manual items.
          </small>
        )}
      </Form.Group>
      <Form.Group controlId="editItemQuantity" className="m-2">
        <Form.Label>Quantity</Form.Label>
        <Form.Control
          type="number"
          value={quantity}
          onChange={(ev) => setQuantity(ev.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="editItemVATRate" className="m-2">
        <Form.Label>VAT Rate</Form.Label>
        <VatRateSelector
          value={vatCode}
          config={config.data}
          onChange={(ev) => setVatCode(ev)}
        />
      </Form.Group>
      <Modal.Footer>
        <Button
          onClick={() => {
            onHide();
          }}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            const quan = Number(quantity);
            onAdd(
              notes,
              quan,
              vatCode,
              ((config.data?.vat.find((i) => i.parameterKey === vatCode)
                ?.valueNumeric ??
                config.data?.vat.find((i) => i.isDefault)?.valueNumeric ??
                0) *
                (invoiceItem?.amountEach ?? 0)) /
                100
            );
          }}
        >
          Save Item
        </Button>
      </Modal.Footer>
    </>
  );
}
