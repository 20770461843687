import { useMemo } from "react";
import {Button, Card, Table} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {Link} from "react-router-dom";
import useData from "../../Hooks/useData";
import { PartialInvoiceRunWithStatus } from "../../Types/Invoicing";

const options = {
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        stepSize: 1
      },
    },
  },
};

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function InvoicingPage() {
  const runs = useData<PartialInvoiceRunWithStatus[]>("/API/Invoicing/Runs");

  const chartRuns = runs.data?.filter(i => i.statusId !== 100).reverse() ?? [];

  const data = useMemo(
    () => ({
      labels:
          chartRuns.map(
          i =>
            `${months[new Date(i.invoiceDate).getMonth()]} ${new Date(i.invoiceDate).getFullYear()}`
        ) ?? [],
      datasets: [
        {
          label: "Invoices",
          backgroundColor: "#A05FF177",
          borderColor: "#A05FF1",
          fill: false,
          data: chartRuns.map(i => i.invoiceCount),
        },
      ],
    }),
    [runs.dataUpdatedAt]
  );
  return (
      <div>
          <h1>Invoicing</h1>
          <Card className="mb-2">
              <Line height={100} type="line" data={data} options={options} />
          </Card>
          <Table size="sm" bordered>
              <thead>
                  <tr className="bg-secondary">
                      <th>Month</th>
                      <th>Invoices</th>
                      <th>Status</th>
                      <th>Actions</th>
                  </tr>
              </thead>
              <tbody>
                  {runs.data
                      ?.slice()
                      .sort((a, b) => b.invoiceRunId - a.invoiceRunId)
                      .map(run => (
                          <tr key={run.invoiceRunId}>
                              <th>
                                  {months[new Date(run.invoiceDate).getMonth()]}{" "}
                                  {new Date(run.invoiceDate).getFullYear()}
                              </th>
                              <td>{(run.invoiceCount || 0).toLocaleString()}</td>
                              <td>
                                  {run.isFailed ? "Failed, Please Retry" : run.statusDescription}
                              </td>
                              <td className="p-0">
                                  {run.statusId < 10 && (
                                      <Button disabled variant="secondary">
                                          No action available
                                      </Button>
                                  )}
                                  {run.statusId === 10 && (
                                      <Button
                                          as={Link}
                                          to={`/process/${run.invoiceRunId}`}
                                          variant="success">
                                          Review Invoices
                                      </Button>
                                  )}
                                  {run.isFailed && (
                                      <Button
                                          as={Link}
                                          to={`/process/${run.invoiceRunId}`}
                                          className="border-0"
                                          variant="danger">
                                          Retry Processing
                                      </Button>
                                  )}
                                  {run.statusId > 10 && (
                                      <Button
                                          as={Link}
                                          to={`/process/${run.invoiceRunId}`}
                                          className="border-0"
                                          variant="outline-primary">
                                          View Invoices
                                      </Button>
                                  )}
                              </td>
                          </tr>
                      ))}
              </tbody>
          </Table>
      </div>
  );
}
