import {CascadingDetails} from "../../../Types/Management";
import {Config} from "../../../Types/Settings";
import {Col, Form, Modal} from "react-bootstrap";
import {UseFormReturn} from "react-hook-form";
import ControlledCheckbox from "../../../Components/ControlledCheckbox";
import useData from "../../../Hooks/useData";
import useAuth from "../../../Hooks/useAuth";
import {useState} from "react";
import {DevTool} from "@hookform/devtools"
import ControlledSelect from "../../../Components/ControlledSelect";

export interface ManagementDetailsEditorProps {
  form: UseFormReturn<CascadingDetails>;
  data?: CascadingDetails;
  type: "client" | "site" | "dept";
}

export default function ManagementDetailsEditor({form, data, type}: ManagementDetailsEditorProps) {
  const auth = useAuth();
  const g = {readOnly: !auth.isAdmin};
  const config = useData<Config>("/API/Config");

  function getInherited(value: string): string | undefined {
    // Workaround for "<Value> as <Type>" syntax not working
    const found: any = data?.inherited?.find((i: any) => i[value]);
    if (found) {
      return `Inherited from ${found.inheritedFrom}: ${found[value]}`
    }
    return undefined;
  }

  return (
    <Form className="p-2">
      <Form.Row>
        <Col md={6}>
          <legend>Details</legend>
          <Form.Group controlId="managementSettingsName">
            <Form.Label>Name</Form.Label>
            <Form.Control {...g} {...form.register("name", {required: true})} type="text"
                          isInvalid={!!form.formState.errors?.name}/>
          </Form.Group>
          <Form.Group controlId="managementSettingsAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              {...g}
              placeholder={getInherited("address")}
              {...form.register("address")}
              as="textarea"
              rows={4}
              type="text"
            />
          </Form.Group>
          <Form.Group controlId="managementSettingsPostcode">
            <Form.Label>Postcode</Form.Label>
            <Form.Control
              {...g}
              placeholder={getInherited("postcode")}
              {...form.register("postcode")}
              type="text"
            />
          </Form.Group>

          {type !== "dept" && (
            <Form.Group controlId="managementSettingsCountry">
              <Form.Label>Country</Form.Label>
              <ControlledSelect
                disabled={g.readOnly}
                control={form.control}
                name="country"
                placeholder={getInherited("country")}
              >
                {type === "site" && <option>{getInherited("country")}</option>}
                {config.data?.countries.map(i => (
                  <option key={i.parameterKey} value={i.parameterKey}>
                    {i.description}
                  </option>
                ))}
              </ControlledSelect>
            </Form.Group>
          )}

          {form.watch("secureDeptId") !== undefined && (
            <Form.Group controlId="managementSettingsSecureDeptId">
              <Form.Label>Legacy Department</Form.Label>
              <Form.Control
                disabled={true}
                value={form.watch("secureDeptId")?.toString() ?? "N/A"}
              />
            </Form.Group>
          )}
          <Form.Row>
            <Col md={6}>
              <Form.Group controlId="managementSettingsActiveCheckbox">
                <ControlledCheckbox
                  disabled={g.readOnly}
                  label="Active?"
                  control={form.control}
                  name="active"
                />
              </Form.Group>
            </Col>
            {/* <Col md={6}>
                            <Form.Group controlId="managementSettingsCSRCheckbox">
                                <ControlledCheckbox
                                    disabled={g.readOnly}
                                    label="Require CSR?"
                                    control={form.control}
                                    name="requireCSR"
                                />
                            </Form.Group>
                        </Col> */}
          </Form.Row>
        </Col>
        <Col md={6}>
          <legend>Contact</legend>
          <Form.Group controlId="managementSettingsContactName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              {...g}
              placeholder={getInherited("contact")}
              {...form.register("contact")}
              type="text"
            />
          </Form.Group>
          <Form.Group controlId="managementSettingsPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              {...g}
              placeholder={getInherited("telephone")}
              {...form.register("telephone")}
              type="text"
            />
          </Form.Group>
          <Form.Group controlId="managementSettingsEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              {...g}
              placeholder={getInherited("email")}
              {...form.register("email")}
              type="email"
            />
          </Form.Group>
          <Form.Group controlId="managementSettingsContactName">
            <Form.Label>Manager</Form.Label>
            <ControlledSelect
              disabled={g.readOnly}
              control={form.control}
              name="country"
              placeholder={getInherited("manager")}
            >
              {(type === "site" || type === "dept") && <option>{getInherited("manager")}</option>}
              <option />
              {config.data?.managers.map(i => (
                <option key={i.parameterKey} value={i.parameterKey}>
                  {i.description}
                </option>
              ))}
            </ControlledSelect>
          </Form.Group>
        </Col>
        <Col md={12}>
          <legend>History</legend>
          <Form.Group controlId="managementSettingsHistoryNote">
            <Form.Label>History Note</Form.Label>
            <Form.Control
              placeholder="History Note"
              readOnly={g.readOnly}
              {...form.register("historyMsg")}
              as="textarea"
              rows={2}
            />
          </Form.Group>
          <Form.Group controlId="managementSettingsHistory">
            <Form.Label>History</Form.Label>
            <Form.Control
              readOnly
              {...form.register("history")}
              as="textarea"
              rows={8}
            />
          </Form.Group>
        </Col>
      </Form.Row><DevTool placement="top-right" control={form.control}/>
    </Form>
  );
}