import {UseQueryOptions, UseQueryResult} from "react-query";
import useData from "./useData";
import {useForm, UseFormReturn} from "react-hook-form";
import { useEffect } from "react";

export interface UseDataFormReturn {
    isValid: boolean;
}

export default function useDataForm<T, F = T>(path: string, parameters: { [key: string]: string | number | null | undefined } = {}, options?: UseQueryOptions<T>): [
    UseQueryResult<T>,
    UseFormReturn<F>,
    UseDataFormReturn,
] {
    const data = useData<T>(path, parameters, {
        refetchInterval: false,
        refetchOnMount: "always",
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...options ?? {}
    });
    const form = useForm<F>({
        //@ts-ignore
        defaultValues: data.data,
        reValidateMode: "onChange",
        mode: "onTouched"
    });
    useEffect(() => {
        if (data.data) {
            const d: any = data.data;
            form.reset(JSON.parse(JSON.stringify(d)));
        }
    }, [data.dataUpdatedAt]);
    return [data, form, {isValid: form.formState.isValid || !data.isFetched}];
}
