import { Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { InvoicingSettings } from "../../Types/Settings";
import useAuth from "../../Hooks/useAuth";

export interface InvoicingEditorProps {
    form: UseFormReturn<InvoicingSettings>;
}

export default function InvoicingEditor({ form }: InvoicingEditorProps) {
    const auth = useAuth();
    return <Form>
        <Form.Group controlId="invoicingSettingsFrom">
            <Form.Label>Default Payment Days</Form.Label>
            <Form.Control readOnly={!auth.isAdmin} {...form.register("defaultPaymentDays")} type="text" />
        </Form.Group>
    </Form>
}