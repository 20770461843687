import {Button, Form, Table} from "react-bootstrap";
import ControlledCheckbox from "../../Components/ControlledCheckbox";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import { JobsData } from "../../Types/Settings";
import useAuth from "../../Hooks/useAuth";

export interface OperationsEditorProps {
  form: UseFormReturn<JobsData>;
}

export default function OperationsEditor({form}: OperationsEditorProps) {
  const auth = useAuth();
  const {fields, append} = useFieldArray({control: form.control, name: "jobs"});
  return <>
    <Table striped bordered size="sm" hover className="m-0">
      <thead>
      <tr>
        <th>Job Code</th>
        <th>Description</th>
        <th>Invoice Label</th>
        <th>Default Price</th>
        <th>Active?</th>
        <th>Default?</th>
      </tr>
      </thead>
      <tbody>
      {fields.map((field, index) => {
        // Workaround for "<Value> as <Type>" syntax not working
        const jobString: any = (i: string) => `jobs.${index}.${i}`;

        // Keep Data
        if (field.jobCodeId !== undefined) form.register(jobString("jobCode"))
        return (
          <tr key={index.toString()}>
            {field.jobCodeId === undefined ?
              <td className="p-0"><Form.Control readOnly={!auth.isAdmin} placeholder="Job Code (Required)"
                                                defaultValue={field.jobCode || ""} {...form.register(jobString("jobCode"), {required: true})} />
              </td> : <td>{field.jobCode}</td>}
            <td className="p-0"><Form.Control readOnly={!auth.isAdmin}
                                              defaultValue={field.description || ""} {...form.register(jobString("description"))} />
            </td>
            <td className="p-0"><Form.Control readOnly={!auth.isAdmin}
                                              defaultValue={field.invoiceText || ""} {...form.register(jobString("invoiceText"))} />
            </td>
            <td className="p-0"><Form.Control readOnly={!auth.isAdmin}
                                              defaultValue={field.defaultPrice || undefined} {...form.register(jobString("defaultPrice"), {valueAsNumber: true})} />
            </td>
            <td><ControlledCheckbox disabled={!auth.isAdmin} className="ml-2" id={`${field.id}_active`}
                                    defaultValue={field.active || false} name={jobString("active")}
                                    control={form.control}/></td>
            <td><ControlledCheckbox disabled={!auth.isAdmin} className="ml-2" id={`${field.id}_default`}
                                    defaultValue={field.isDefault || false} name={jobString("isDefault")}
                                    control={form.control}/></td>
          </tr>
        )
      })}
      </tbody>
    </Table>
    {auth.isAdmin && <Button className="m-2" onClick={() => {
      append({
        jobCode: "",
        description: null,
        invoiceText: null,
        defaultPrice: null,
        active: true,
        visible: true,
        isDefault: false,
      })
    }}>Add Job Code</Button>}
  </>
}