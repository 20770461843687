import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useData from "../../../Hooks/useData";

import { MinimalDept } from "../../../Types/Management";

export default function CountPerJobCode() {
    const { run: runID } = useParams<{ run: string }>();
    const unmappedDepts = useData<[string, number][]>("/API/SecureDepts", {
        run: runID,
    });
        
    if (!unmappedDepts.data) {
        return <h6>Loading...</h6>;
    }
    var nullAny: any = null;
    return (
        <div>
            <div className="d-flex flex-row align-items-center pt-2 pb-2">
                <h3 className="flex-grow-1">Secure depts not mapped to AutoImaging depts</h3>                
            </div>
            <Table size="sm" bordered>
                <thead>
                    <tr className="bg-secondary">
                    <th>Number</th>
                    <th>Full Name</th>
                    </tr>
                </thead>
                <tbody>
                    {unmappedDepts.data.map(i => (
                        <tr key={i[1]}>
                            <td>{i[1]}</td>
                            <td>{i[0]}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
