import { useMemo } from "react";
import { Button, Card, Row, Table } from "react-bootstrap";
import { Table as RBITable } from "react-bootstrap-icons";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import useData from "../../../Hooks/useData";

import { CountByJobCode } from "../../../Types/Reports";
import { formatNumber } from "../../../Utils/Format";
import { saveWorkbook, useChartColours } from "./Utils";
import ExcelJS from "exceljs";

const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
};

function generateSpreadsheet(data: CountByJobCode[], runID: string) {
    const workbook = new ExcelJS.Workbook();
    workbook.calcProperties.fullCalcOnLoad = true;

    const sheet = workbook.addWorksheet("Count per job code", {
        views: [{ state: "frozen", ySplit: 1 }],
    });

    sheet.columns = [
        { header: "Code", key: "jobCode", width: 15 },
        { header: "Total Quantity", key: "quantitySum", width: 15 },
    ];

    const header = sheet.getRow(1);
    let index = 0;
    for (const col of ["Code", "Total Quantity"]) {
        index++;
        header.getCell(index).value = col;
        header.getCell(index).style = { font: { bold: true } };
        header.getCell(index).border = { bottom: { style: "thick" } };
    }

    for (let item = 0; item < data.length; item++) {
        const row = sheet.getRow(item + 2);
        row.getCell("jobCode").value = data[item].jobCode;
        row.getCell("quantitySum").value = data[item].quantitySum;
    }

    sheet.getRow(data.length + 2).getCell(2).style = { font: { bold: true } };
    sheet.getRow(data.length + 2).getCell(2).value = {
        formula: `=SUM(B2:B${data.length + 1})`,
        result: data.reduce((a, b) => a + b.quantitySum, 0),
        sharedFormula: `=SUM(B2:B${data.length + 1})`,
        date1904: false,
    };
    sheet.getRow(data.length + 2).getCell(2).border = {
        top: { style: "thick" },
    };

    saveWorkbook(`Invoice Run ${runID} - Count Per Job Code.xlsx`, workbook);
}

export default function CountPerJobCode() {
    const { run: runID } = useParams<{ run: string }>();
    const countByJobCode = useData<CountByJobCode[]>("/API/Reports/Runs/{run}/CountPerJobCode", {
        run: runID,
    });
    const chartColours = useChartColours(countByJobCode.data?.length ?? 0);
    const data = useMemo(() => {
        if (countByJobCode.data) {
            return {
                labels: countByJobCode.data.map(i => i.jobCode ?? ""),
                datasets: [
                    {
                        label: "Total Quantity",
                        data: countByJobCode.data.map(i => i.quantitySum),
                        ...chartColours,
                        borderWidth: 1,
                    },
                ],
            };
        }
        return null;
    }, [countByJobCode.dataUpdatedAt, chartColours]);

    if (!countByJobCode.data) {
        return <h6>Loading...</h6>;
    }
    var nullAny: any = null;
    return (
        <div>
            <div className="d-flex flex-row align-items-center pt-2 pb-2">
                <h3 className="flex-grow-1">Count by Job Code</h3>
                <Button
                    onClick={() => {
                        generateSpreadsheet(countByJobCode.data ?? [], runID);
                    }}
                    variant="success">
                    Export to Excel <RBITable />
                </Button>
            </div>
            <Card className="mb-2">
                <Bar type="bar" data={data} height={500} width={nullAny} options={chartOptions} />
            </Card>
            <Table size="sm" bordered>
                <thead>
                    <tr className="bg-secondary">
                        <th>Job Code</th>
                        <th>Total Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {countByJobCode.data.map(i => (
                        <tr key={i.jobCode}>
                            <td className="font-weight-bold">{i.jobCode ?? ""}</td>
                            <td className="text-right text-monospace">
                                {formatNumber(i.quantitySum, "NUM")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
