import { useParams } from "react-router-dom";
import { CascadingDetails, ContractsData } from "../../Types/Management";
import ManagementDetailsEditor from "./Editors/Details";
import { Button, Tab, Tabs } from "react-bootstrap";
import useDataForm from "../../Hooks/useDataForm";
import useDataMutation from "../../Hooks/useDataMutation";
import ManagementInvoicingEditor from "./Editors/Invoicing";
import { useState, useEffect } from "react";
import ManagementContractsEditor from "./Editors/Contracts";
import useAuth from "../../Hooks/useAuth";
import { Prompt } from 'react-router';

interface ManagementPageParams {
  client: string;
}

export default function ManagementClient() {
  const auth = useAuth();
  const params = useParams<ManagementPageParams>();
  const [client, clientForm, clientFormProperties] = useDataForm<CascadingDetails>(
    "/API/Clients/{client}",
    { client: params.client }
  );
  const clientMutation = useDataMutation<CascadingDetails, CascadingDetails>(
    "/API/Clients/{client}",
    { client: params.client }
  );
  const [tab, setTab] = useState<string | null>("details");
  const [_, clientContractsForm, clientContractsProperties] = useDataForm<ContractsData>(
    "/API/Clients/{client}/Contracts",
    { client: params.client },
    { enabled: tab === "contracts" }
  );
  const clientContractsMutation = useDataMutation<ContractsData, ContractsData>(
    "/API/Clients/{client}/Contracts",
    { client: params.client },
    { enabled: tab === "contracts" }
  );

  const hasErrors = !clientFormProperties.isValid || !clientContractsProperties.isValid;
  const hasChanges = clientForm.formState.isDirty || clientContractsForm.formState.isDirty;
  return (
    <>
<Prompt
    when={hasChanges}
    message='You have unsaved changes, are you sure you want to leave?'
/>
<div className="mt-2">
    <div className="d-flex flex-row align-items-center">
        <h4 className="flex-grow-1">{client.data?.name ?? "Loading..."}</h4>
        {auth.isAdmin && (          
          <>
          {hasErrors && <span className="text-danger mr-2">Please correct errors before saving</span>}
          <Button
            onClick={() => {
              clientForm.handleSubmit(() => {
                if (clientForm.formState.isDirty)
                  clientMutation.mutate(clientForm.getValues());
              })();
              clientContractsForm.handleSubmit(() => {
                if (clientContractsForm.formState.isDirty)
                  clientContractsMutation.mutate({
                    contracts: clientContractsForm
                      .getValues()
                      .contracts.filter((i) => !i.inherited),
                  });
              })();
            }}
            disabled={!hasChanges}
          >
            Save
          </Button>
          </>
        )}
    </div>

    {!!client.data && (
        <Tabs activeKey={tab} onSelect={(t) => setTab(t)} id="managementTabs">
          <Tab eventKey="details" title="Details">
            <ManagementDetailsEditor form={clientForm} data={client.data} type="client" />
          </Tab>
          <Tab eventKey="invoicing" title="Invoicing">
            <ManagementInvoicingEditor form={clientForm} data={client.data} type="client" />
          </Tab>
          <Tab eventKey="contracts" title="Contracts">
            <ManagementContractsEditor form={clientContractsForm} />
          </Tab>
        </Tabs>
      )}
</div>
</>
  );
}
