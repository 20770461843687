import {useState} from "react";
import {Accordion, Card, Button, ProgressBar, Modal} from "react-bootstrap";
import Invoices from "./Invoices";
import useData from "../../../Hooks/useData";
import {useParams} from "react-router";
import {InvoiceRun, Invoice} from "../../../Types/Invoicing";
import {formatNumber} from "../../../Utils/Format";
import {Link} from "react-router-dom";
import AddManualInvoice from "./AddManualInvoice";
import useDataMutation from "../../../Hooks/useDataMutation";
import SageExport from "../Sage/SageExport";
import { useQueryClient } from "react-query";
import { useHistory } from 'react-router-dom';

function monthRange(start: string, endExclusive: string) {
  const date = new Date(start);
  const end = new Date(new Date(endExclusive).valueOf() - 1);
  return `${date.toDateString()} - ${end.toDateString()}`;
}

export default function MainPage() {
  const [addManualInvoice, setAddManualInvoice] = useState(false);
  const [sageExport, setSageExport] = useState(false);
  const {run: runID} = useParams<{ run: string }>();
  const run = useData<{ testSendAllEmailsRecipient: string | null; details: InvoiceRun; invoices: Invoice[] }>("/API/Invoicing/Runs/{run}", {
    run: runID,
  });
  const history = useHistory();
  const queryClient = useQueryClient();
  const status = useData<{ statusId: number; statusDescription: string; isFailed: boolean | null }>(
    "/API/Invoicing/Runs/{run}/Status",
    {
      run: runID,
    },
    {refetchInterval: 10000}
  );
  const addInvoice = useDataMutation(
    "/API/Invoicing/Runs/{runID}/Invoices/Add",
    {
      runID,
    },
    {},
    [{queryKey: ["/API/Invoicing/Runs/{run}"]}]
  );
  const approveRun = useDataMutation(
    "/API/Invoicing/Runs/{runID}/Approve",
    {
      runID,
    },
    {},
    [
      {queryKey: ["/API/Invoicing/Runs/{run}"]},
      {queryKey: ["/API/Invoicing/Runs/{run}/Status"]},
    ]
    );
    const retryRun = useDataMutation(
      "/API/Invoicing/Runs/{runID}/Retry",
      {
        runID,
      },
      {},
      [
        {queryKey: ["/API/Invoicing/Runs/{run}"]},
        {queryKey: ["/API/Invoicing/Runs/{run}/Status"]},
      ]
      );
  const reprocessRun = useDataMutation(
      "/API/Invoicing/Runs/{runID}/Reprocess",
      {
          runID,
      },
      {},
      [
          { queryKey: ["/API/Invoicing/Runs/{run}"] },
          { queryKey: ["/API/Invoicing/Runs/{run}/Status"] },
      ]
  );
  if (!run.data) {
    return <h6>Loading...</h6>;
  }
  if (!status.data) {
    return <h6>Loading...</h6>;
  }
  const statusDetails = status.data;

  if (statusDetails.statusId == 5 && !statusDetails.isFailed) {
    return <h6>Run is still being calculated.</h6>;
  }
  
  const {testSendAllEmailsRecipient, details, invoices} = run.data;
  const invalidateSageQuery = async () =>{
    await queryClient.invalidateQueries(["/API/Exports/Runs/{run}/SageStatus"]);
  }

  const hasInvoiceWithNoItems = invoices.filter(i => i.itemCount === 0).length > 0;
  const hasInvoiceWithNoEmail = invoices.filter(i => (i.emailAddressesToSendTo ?? "").length === 0).length > 0;
  const hasInvoiceWithNoAccountsCode = invoices.filter(i => (i.accountCodeToAllocateTo ?? "").length === 0).length > 0;

  return (
    <div>
        <h1>Invoicing</h1>
        <h6>Invoices for period {monthRange(details.invoiceRangeStart, details.invoiceRangeEndExclusive)}</h6>
        <h4>Review</h4>
        <Accordion defaultActiveKey="0">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} className="p-0" variant="white" eventKey="0">
                        Summary Analysis
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <p className="text-bold">
                            {invoices.length} Invoice{invoices.length === 1 ? "" : "s"} Created
                        </p>
                        {/* Total Positive */}
                        <p>
                            Total Invoiced:{" "}
                            {formatNumber(
                  invoices.filter(i => i.net > 0).reduce((a, b) => a + b.net, 0),
                  "GBP"
                )}
                        </p>
                        {/* Total Negative */}
                        <p>
                            Total Credit Notes:{" "}
                            {formatNumber(
                  invoices.filter(i => i.net < 0).reduce((a, b) => a + b.net, 0),
                  "GBP"
                )}
                        </p>
                        <p>
                            Total Net Sales Value:{" "}
                            {formatNumber(
                  invoices.reduce((a, b) => a + b.net, 0),
                  "GBP"
                )}
                            <br/>
                            Total VAT Value:{" "}
                            {formatNumber(
                  invoices.reduce((a, b) => a + b.vat, 0),
                  "GBP"
                )}
                            <br/>
                            Total Gross Sales Value:{" "}
                            {formatNumber(
                  invoices.reduce((a, b) => a + b.gross, 0),
                  "GBP"
                )}
                        </p>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                    <Button
                        as={Link}
                        variant="white"
                        to={`/process/${runID}/reports/invoicedperdept`}
                        className="p-0">
                        View Reports
                    </Button>
                </Card.Header>
            </Card>
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} className="p-0" variant="white" eventKey="3">
                        View{" "}
                        {formatNumber(invoices.filter(i => !i.manualInvoice).length, "NUM")}{" "}
                        Invoice
                        {invoices.filter(i => !i.manualInvoice).length === 1 ? "" : "s"}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Invoices
                        readOnly={details.statusId !== 10}
                        invoices={invoices.filter(i => !i.manualInvoice)}/>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} className="p-0" variant="white" eventKey="4">
                        View {formatNumber(invoices.filter(i => i.manualInvoice).length, "NUM")}{" "}
                        Credit Note
                        {invoices.filter(i => i.manualInvoice).length === 1 ? "" : "s"}
                        /Manual Invoice
                        {invoices.filter(i => i.manualInvoice).length === 1 ? "" : "s"}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <div>
                        {details.statusId === 10 && (
                <div className="p-2 d-flex flex-row justify-content-end">
                  <Button
                    onClick={() => {
                      setAddManualInvoice(true);
                    }}>
                    Add Manual Invoice
                  </Button>
                </div>
              )}
                        <Invoices
                            readOnly={details.statusId !== 10}
                            invoices={invoices.filter(i => i.manualInvoice)}/>
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
        <Modal
            show={addManualInvoice}
            onHide={() => setAddManualInvoice(false)}
            onBackdropClick={() => setAddManualInvoice(false)}>
            <AddManualInvoice
                onSelect={(deptId, invoiceGroupId) => {
            if (deptId || invoiceGroupId) {
              addInvoice
                .mutateAsync({
                  invoiceGroupId,
                  deptId,
                })
                .then(() => {
                  setAddManualInvoice(false);
                });
            } else {
              setAddManualInvoice(false);
            }
          }}/>
        </Modal>
        {statusDetails.isFailed ? (
        <>
          <h4 className="mt-2">
            Invoice Run Failed at {statusDetails.statusDescription}
          </h4>
          <ProgressBar
            variant="danger"
            striped
            animated={statusDetails.statusId < 35}
            now={((statusDetails.statusId - 20) / 15) * 100}
          />
          <Button
            onClick={() => {
              retryRun.mutateAsync({});
            }}
            className="mt-2"
            variant="success">
            Retry Processing
          </Button>
        </>
      ) : statusDetails.statusId > 10 ? (
        statusDetails.statusId === 100 ? (
          <h4 className="mt-2">Invoice Run Cancelled.</h4>
        ) : statusDetails.statusId === 40 ? (
          <>
            <h4 className="mt-2">Invoice Run Completed.</h4>
            <Button
              onClick={async () => {
                await invalidateSageQuery()
                setSageExport(true);
              }}
              className="mt-2"
              variant="primary">
              Sage Export
            </Button>
            <Modal
              show={sageExport}
              onHide={() => setSageExport(false)}
              onBackdropClick={() => setAddManualInvoice(false)}>
              <SageExport
                runId = {Number(runID)}
              />
            </Modal>
          </>
        ) : (
          <>
            <h4 className="mt-2">
              Invoice Run Approved - {statusDetails.statusDescription}.
            </h4>
            <ProgressBar
              striped
              animated={statusDetails.statusId < 35}
              now={((statusDetails.statusId - 20) / 15) * 100}
            />
          </>
        )
      ) : (
        <div className="d-flex flex-row-reverse">
                          <div className={"text-right"}>
                             
            <Button
              disabled={hasInvoiceWithNoItems || hasInvoiceWithNoEmail || hasInvoiceWithNoAccountsCode}
              onClick={() => {
                  if (confirm("Complete this run and send invoices to customers?")) {
                      approveRun.mutateAsync({});
                  }
              }}
              className="mt-2"
              variant="success">
              Approve and Process
            </Button>
            {hasInvoiceWithNoItems &&
            <div className="text-muted mt-2">In order to approve, please remove invoices with no items</div>}
            {hasInvoiceWithNoEmail &&
            <div className="text-muted mt-2">In order to approve, please add email addresses where missing</div>}
            {hasInvoiceWithNoAccountsCode &&
            <div className="text-muted mt-2">In order to approve, please add accounts code where missing</div>}
            {testSendAllEmailsRecipient &&
                                  <div className="text-warning mt-2">Emails will be sent to {testSendAllEmailsRecipient}</div>
                              }
            <hr /> <Button
                       onClick={async () => {
                                      await reprocessRun.mutateAsync({});
                                      queryClient.refetchQueries(["/API/Invoicing/Runs"]);
                                      history.push("/");
                       }}
                       className="mt-2"
                       variant="info">
                Recalculate
            </Button>

          </div>
        </div>
      )}
    </div>
  );
}
