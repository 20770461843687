import {Button, Col, Form, Table} from "react-bootstrap";
import ControlledCheckbox from "../../Components/ControlledCheckbox";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import { CompanySettings, SettingsData } from "../../Types/Settings";
import { JobsData } from "../../Types/Management";
import {UseMutationResult} from "react-query";
import useAuth from "../../Hooks/useAuth";

export interface CompanyEditorProps {
  form: UseFormReturn<CompanySettings>;
}

export default function CompanyEditor({form}: CompanyEditorProps) {
  const auth = useAuth();
  return <Form>
    <Form.Row>
      <Col md={6}>
        <Form.Group controlId="companySettingsName">
          <Form.Label>Name</Form.Label>
          <Form.Control disabled={!auth.isAdmin} {...form.register("name")} type="text"/>
        </Form.Group>
        <Form.Group controlId="companySettingsMessage">
          <Form.Label>Address</Form.Label>
          <Form.Control disabled={!auth.isAdmin} {...form.register("address")} type="text" as="textarea" rows={4} />
        </Form.Group>
        <Form.Group controlId="companySettingsPostcode">
          <Form.Label>Postcode</Form.Label>
          <Form.Control disabled={!auth.isAdmin} {...form.register("postcode")} type="text"/>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group controlId="companySettingsRegistration">
          <Form.Label>Registration Number</Form.Label>
          <Form.Control disabled={!auth.isAdmin} {...form.register("registration")} type="text"/>
        </Form.Group>
        <Form.Group controlId="companySettingsVATNumber">
          <Form.Label>VAT Number</Form.Label>
          <Form.Control disabled={!auth.isAdmin} {...form.register("vatNumber")} type="text"/>
        </Form.Group>
        <Form.Group controlId="companySettingsAccountCode">
          <Form.Label>Account Code</Form.Label>
          <Form.Control disabled={!auth.isAdmin} {...form.register("accountCode")} type="text"/>
        </Form.Group>
        <Form.Group controlId="companySettingsSortCode">
          <Form.Label>Sort Code</Form.Label>
          <Form.Control disabled={!auth.isAdmin} {...form.register("sortCode")} type="text"/>
        </Form.Group>
      </Col>
    </Form.Row>
  </Form>
}