import {useParams} from "react-router-dom";
import {CascadingDetails, ContractsData, Dept, MinimalGroup} from "../../Types/Management";
import ManagementDetailsEditor from "./Editors/Details";
import {Button, Tab, Tabs} from "react-bootstrap";
import useDataForm from "../../Hooks/useDataForm";
import useDataMutation from "../../Hooks/useDataMutation";
import ManagementInvoicingEditor from "./Editors/Invoicing";
import {useState, useEffect} from "react";
import ManagementContractsEditor from "./Editors/Contracts";
import useData from "../../Hooks/useData";
import useAuth from "../../Hooks/useAuth";
import { Prompt } from 'react-router';


interface ManagementPageParams {
  client: string;
  site: string;
  dept: string;
}

export default function ManagementDept() {
  const auth = useAuth();
  const params = useParams<ManagementPageParams>()
  const [dept, deptForm, deptProperties] = useDataForm<Dept, CascadingDetails>("/API/Clients/{client}/Sites/{site}/Depts/{dept}", {
    client: params.client,
    site: params.site,
    dept: params.dept
  });
  const deptMutation = useDataMutation<CascadingDetails, CascadingDetails>("/API/Clients/{client}/Sites/{site}/Depts/{dept}", {
    client: params.client,
    site: params.site,
    dept: params.dept
  });
  const [tab, setTab] = useState<string | null>("details");
  const [_, deptContractsForm, deptContractsProperties] = useDataForm<ContractsData>(
      "/API/Clients/{client}/Sites/{site}/Depts/{dept}/Contracts",
      {
          client: params.client,
          site: params.site,
          dept: params.dept,
      },
      { enabled: tab === "contracts" }
  );
  const deptContractsMutation = useDataMutation<ContractsData, ContractsData>("/API/Clients/{client}/Sites/{site}/Depts/{dept}/Contracts", {
    client: params.client,
    site: params.site,
    dept: params.dept
  }, {enabled: tab === "contracts"});
  const groups = useData<MinimalGroup[]>("/API/Clients/{client}/Groups", {client: params.client});
  const hasErrors = !deptProperties.isValid || !deptContractsProperties.isValid;
  const hasChanges = deptForm.formState.isDirty || deptContractsForm.formState.isDirty;

  return (
      <>
<Prompt
    when={hasChanges}
    message='You have unsaved changes, are you sure you want to leave?'
/>
    <div className="mt-2">
      <div className="d-flex flex-row align-items-center"><h4
        className="flex-grow-1">{dept.data?.name ?? "Loading..."}</h4>
        {auth.isAdmin && <>
          {hasErrors && <span className="text-danger mr-2">Please correct errors before saving</span>}
          <Button
            onClick={() => {
              deptForm.handleSubmit(() => {
                if (deptForm.formState.isDirty)
                deptMutation.mutate(deptForm.getValues());
              })();
              deptContractsForm.handleSubmit(() => {
                if (deptContractsForm.formState.isDirty)
                deptContractsMutation.mutate({
                    contracts: deptContractsForm
                      .getValues()
                      .contracts.filter((i) => !i.inherited),
                  });
              })();
            }}
            disabled={!hasChanges}
          >
            Save
          </Button>
          </>}
      </div>
      
      {!!dept.data && <Tabs activeKey={tab} onSelect={t => setTab(t)} id="managementTabs">
          <Tab eventKey="details" title="Details">
              <ManagementDetailsEditor form={deptForm} data={dept.data} type="dept"/>
          </Tab>
          <Tab eventKey="invoicing" title="Invoicing">
              <ManagementInvoicingEditor disabled={!!dept.data?.invoiceGroupId} form={deptForm} data={dept.data} type="dept"/>
          </Tab>
          <Tab eventKey="contracts" title="Contracts">
              <ManagementContractsEditor form={deptContractsForm}/>
          </Tab>
      </Tabs>}
    </div>
</>
  );
}
