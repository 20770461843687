import { Alert, Col, Form} from "react-bootstrap";
import {UseFormReturn} from "react-hook-form";
import ControlledCheckbox from "../../../Components/ControlledCheckbox";
import { CascadingDetails } from "../../../Types/Management";
import useData from "../../../Hooks/useData";
import { Config } from "../../../Types/Settings";
import useAuth from "../../../Hooks/useAuth";
import ControlledSelect from "../../../Components/ControlledSelect";

export interface ManagementInvoicingEditorProps {
    form: UseFormReturn<CascadingDetails>;
    data?: CascadingDetails;
    disabled?: boolean;
    type: "client" | "site" | "dept";
}

interface InheritedVatCode{
    key: string;
    description: string;
}

export default function ManagementInvoicingEditor({form, data, disabled, type}: ManagementInvoicingEditorProps) {
    const auth = useAuth();
    const g = {readOnly: !auth.isAdmin || disabled};
    const config = useData<Config>("/API/Config");
    function getInherited(value: string): string | undefined {
        // Workaround for "<Value> as <Type>" syntax not working
        const found: any = data?.inherited?.find((i: any) => i[value]);
        if (found) {
            return `Inherited from ${found.inheritedFrom}: ${found[value]}`;
        }
        return undefined;
    }

    function getInheritedVatCode() : InheritedVatCode | null {
        const key = "vatCode";
        const found: any = data?.inherited?.find((i: any) => i[key]);
        if (found) {
            const code = found[key];
            return {
                key: code,
                description: `Inherited from ${found.inheritedFrom}: ${code}` 
            };
        }
        return null;
    }

    const inheritedVatCode = getInheritedVatCode();

    return (<>{!!disabled &&
      <Alert variant="warning">Invoicing Settings for this Department will not be applied as this Department is in an
          Invoicing
          Group</Alert>}
        <Form className="p-2">
            <Form.Row>
                <Col md={6}>
                    <legend>Invoicing</legend>
                    <Form.Group controlId="managementSettingsName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            placeholder={getInherited("invoiceName")}
                            {...g}
                            {...form.register("invoiceName")}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="managementSettingsAddress">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            placeholder={getInherited("invoiceAddress")}
                            {...g}
                            {...form.register("invoiceAddress")}
                            as="textarea"
                            rows={4}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="managementSettingsPostcode">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                            placeholder={getInherited("invoicePostcode")}
                            {...g}
                            {...form.register("invoicePostcode")}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Row>
                        <Col md={6}>
                            <Form.Group controlId="managementSettingsEmailCheckbox">
                                <ControlledCheckbox
                                    disabled={g.readOnly}
                                    label="Email Invoices?"
                                    control={form.control}
                                    name="emailInvoices"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="managementSettingsPrintCheckbox">
                                <ControlledCheckbox
                                    disabled={g.readOnly}
                                    label="Print Invoices?"
                                    control={form.control}
                                    name="printInvoices"
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Col>
                <Col md={6}>
                    <legend>Contact</legend>
                    <Form.Group controlId="managementSettingsContactName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            placeholder={getInherited("invoiceContact")}
                            {...g}
                            {...form.register("invoiceContact")}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="managementSettingsPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            placeholder={getInherited("invoiceTelephone")}
                            {...g}
                            {...form.register("invoiceTelephone")}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="managementSettingsEmail">
                        <Form.Label>Email Addresses</Form.Label>
                        <Form.Control
                            placeholder={getInherited("invoiceEmailAddresses")}
                            {...g}
                            {...form.register("invoiceEmailAddresses")}
                            type="email"
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <legend>VAT</legend>
                    <Form.Group controlId="managementSettingsVATNumber">
                        <Form.Label>VAT Number</Form.Label>
                        <Form.Control
                            placeholder={getInherited("vatNumber")}
                            {...g}
                            {...form.register("vatNumber")}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="managementSettingsVATCode">
                        <Form.Label>VAT Code</Form.Label>
                        <ControlledSelect
                          disabled={g.readOnly}
                          control={form.control}
                          name="vatCode"
                          placeholder={getInherited("vatCode")}
                        >
                            {inheritedVatCode && (<option value="">{inheritedVatCode.description}</option>)}
                            {!inheritedVatCode && (<option value="">System Default</option>)}
                            {config.data?.vat.map(i => (
                              <option key={i.parameterKey} value={i.parameterKey}>
                                  {i.description}
                              </option>
                            ))}
                        </ControlledSelect>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <legend>Other</legend>
                    <Form.Group controlId="managementSettingsRegistration">
                        <Form.Label>Registration</Form.Label>
                        <Form.Control
                            placeholder={getInherited("registration")}
                            {...g}
                            {...form.register("registration")}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="managementSettingsAccountsCode">
                        <Form.Label>Accounts Code</Form.Label>
                        <Form.Control
                            placeholder={getInherited("accountsCode")}
                            {...g}
                            {...form.register("accountsCode")}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="managementSettingsPaymentDays">
                        <Form.Label>Payment Days</Form.Label>
                        <Form.Control
                            placeholder={getInherited("paymentDays")}
                            {...g}
                            {...form.register("paymentDays")}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Form.Row>
        </Form></>
    );
}