import { useEffect, useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import {Link} from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PDFModal({
  title,
  url,
  close,
}: {
  title?: string;
  url?: string | null;
  close(): void;
}) {
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  useEffect(() => {
    setPage(1);
  }, [url]);
  return (
    <Modal
      show={!!url}
      onHide={() => close()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className={"d-flex flex-row align-items-center"}>
        <Modal.Title id="contained-modal-title-vcenter" className={"flex-grow-1"}>{title}</Modal.Title>
        {!!url && <Button className="mr-2" as="a" href={url} target="_blank">
          Open in New Tab
        </Button>}
        <CloseButton onClick={() => close()} />
      </Modal.Header>
      <div className="pdf-doc-wrapper">
        <Document
          className="d-flex flex-column align-items-center"
          file={url}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
          <Page pageNumber={page} />
        </Document>
      </div>
      <div className="d-flex flex-row">
        <Button onClick={() => setPage(page - 1)} disabled={page === 1} variant="link">
          Prev
        </Button>
        <div className="text-center flex-grow-1 h6 m-2">
          Page {page} of {numPages}
        </div>
        <Button onClick={() => setPage(page + 1)} disabled={page === numPages} variant="link">
          Next
        </Button>
      </div>
    </Modal>
  );
}
