import {useController, UseControllerProps} from "react-hook-form";
import {Form} from "react-bootstrap";

interface CheckboxProps {
    label?: string;
    id?: string;
    className?: string;
    disabled?: boolean
}

export default function ControlledCheckbox<TFieldValues>(props: Omit<UseControllerProps<TFieldValues>, "defaultValue"> & CheckboxProps & {defaultValue?: any}) {
    const controller = useController(props);
    
    // Workaround for "<Value> as <Type>" syntax not working
    const value: any = controller.field.value;
    
    return <Form.Check disabled={props.disabled} id={props.id} label={props.label} className={props.className} custom checked={value} onChange={controller.field.onChange} />
}