import {
    Alert,
    Button,
    Col,
    Container,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import ProcessPage from "./Pages/Invoicing/Process";
import ReportsPage from "./Pages/Invoicing/Reports";
import SettingsPage from "./Pages/Settings/index";
import ManagementPage from "./Pages/Management/index";
import UsersPage from "./Pages/Users";
import InvoicingPage from "./Pages/Invoicing";
import { QueryClient, QueryClientProvider } from "react-query";
import LoadingSpinner from "./Components/LoadingSpinner";
import useAuth from "./Hooks/useAuth";
import {useThemeToggle} from "./Hooks/useTheme";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 2,
            staleTime: 60000,
        },
    },
});

function App() {
    const auth = useAuth();
    const toggleTheme = useThemeToggle();
    if (auth.isLoading) {
        return (
            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
    if (!auth.isAuthenticated) {
        return (
            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                {new URLSearchParams(location.search).has("failed") && (
                    <Alert variant="danger">
                        You do not have permission to access AutoImaging Invoicing
                    </Alert>
                )}
                <h4>Welcome to AutoImaging Invoicing</h4>
                <Button onClick={() => auth.login()}>Login with Microsoft 365</Button>
            </div>
        );
    }
    return (
        <>
            <Navbar
                className="main-header"
                expand="lg"
                bg="secondary"
                variant="dark"
                style={{ position: "fixed", top: 0, left: 0, right: 0 }}>
                <Navbar.Brand as={Link} to="/">
                    <img
                        alt=""
                        src="https://www.autoimaging.co.uk/wp-content/themes/autoimaging/static/min/img/logo.png"
                        height="30"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <LoadingSpinner className="align-self-center d-block d-lg-none ml-auto mr-3" />
                <Navbar.Toggle aria-controls="navbarCollapse" />
                <Navbar.Collapse id="navbarCollapse">
                    <Nav className="flex-grow-1">
                        <Nav.Link as={Link} to="/">
                            Invoicing
                        </Nav.Link>
                        <Nav.Link as={Link} to="/management">
                            Management
                        </Nav.Link>
                        <Nav.Link as={Link} to="/users">
                            Users
                        </Nav.Link>
                        <Nav.Link className="mr-auto" as={Link} to="/settings">
                            Settings
                        </Nav.Link>
                        <LoadingSpinner className="align-self-center d-none d-lg-block" />
                        <NavDropdown
                            alignRight
                            title={`${auth.isAdmin ? "🔑 " : ""}${auth.name}`}
                            id="accountDropdown">
                            <NavDropdown.Item as="a" href="/API/Auth/Logout">
                                Logout
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => {
                                    toggleTheme();
                                }}>
                                Toggle Dark Theme
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Container style={{ marginTop: 56 }} fluid>
                <Switch>
                    <Route path="/settings">
                        <Row className="justify-content-md-center">
                            <Col xs={12} lg={10} xl={8}>
                                <SettingsPage />
                            </Col>
                        </Row>
                    </Route>
                    <Route path="/users">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={8}>
                                <UsersPage />
                            </Col>
                        </Row>
                    </Route>
                    <Route path="/management/:client?/group/:group?">
                        <ManagementPage />
                    </Route>
                    <Route path="/management/:client?/:site?/:dept?">
                        <ManagementPage />
                    </Route>
                    <Route path="/process/:run/reports/:report">
                        <ReportsPage />
                    </Route>
                    <Route path="/process/:run/reports">
                        <ReportsPage />
                    </Route>
                    <Route path="/process/:run">
                        <Row className="justify-content-md-center">
                            <Col xs={12} xl={10}>
                                <ProcessPage />
                            </Col>
                        </Row>
                    </Route>
                    <Route path="/">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={8}>
                                <InvoicingPage />
                            </Col>
                        </Row>
                    </Route>
                </Switch>
            </Container>
        </>
    );
}

export default function AppWrapper() {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <App />
            </Router>
        </QueryClientProvider>
    );
}
