import {Button, Form, Table} from "react-bootstrap";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import { SettingsData } from "../../Types/Settings";
import useAuth from "../../Hooks/useAuth";

export interface GeneralEditorProps {
  form: UseFormReturn<SettingsData>;
  data?: SettingsData;
}

export default function ManagerEditor({form, data}: GeneralEditorProps) {
  const auth = useAuth();
  const {fields: vatFields, append: vatAppend} = useFieldArray({control: form.control, name: "vat"});
  const {fields: managerFields, append: managerAppend} = useFieldArray({control: form.control, name: "managers"});
  return <>
    <Table striped bordered size="sm" hover className="m-0">
      <thead>
      <tr>
        <th>ID</th>
        <th>Short Name</th>
        <th>Full Name</th>
      </tr>
      </thead>
      <tbody>
      {managerFields.map((field, index) => {
        // Workaround for "<Value> as <Type>" syntax not working
        const jobString: any = (i: string) => `managers.${index}.${i}`;

        // Keep Data
        if (!field.newManager) form.register(jobString("parameterKey"))
        return (
          <tr key={index.toString()}>
            {field.newManager ?
              <td className="p-0">
                <Form.Control
                  readOnly={!auth.isAdmin}
                  placeholder="ID (Required)"
                  defaultValue={field.parameterKey || ""}
                  {...form.register(jobString("parameterKey"), {required: true})}
                />
              </td> : <td>{field.parameterKey}</td>}
            <td className="p-0">
              <Form.Control
                readOnly={!auth.isAdmin}
                defaultValue={field.description || ""}
                {...form.register(jobString("description"))}
              />
            </td>
            <td className="p-0">
              <Form.Control
                readOnly={!auth.isAdmin}
                defaultValue={field.valueString ?? ""}
                {...form.register(jobString("valueString"))}
              />
            </td>
          </tr>
        )
      })}
      </tbody>
    </Table>
    {auth.isAdmin && <Button className="m-2" onClick={() => {
      managerAppend({
        parameterKey: "",
        description: "",
        isDefault: false,
        valueString: "",
        newManager: true,
      })
    }}>Add Manager</Button>}
  </>
}