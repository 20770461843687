import useData from "./useData";

interface AuthStatus {
  isAuthenticated: boolean;
  isAdmin: boolean;
  name: string | null;
}

export interface useAuthResponse {
  name: string | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLoading: boolean;
  login(): void;
}

export default function useAuth(): useAuthResponse {
  const data = useData<AuthStatus>("/API/Auth/Status");
  function login() {
    location.href = "/API/Auth/Login";
  }
  if(data.isLoading) {
    return {
      name: null,
      isAuthenticated: false,
      isAdmin: false,
      isLoading: true,
      login(){},
    }
  }
  if(data.isError || !data.data?.isAuthenticated) {
    return {
      name: null,
      isAuthenticated: false,
      isAdmin: false,
      isLoading: false,
      login,
    }
  }
  return {
    name: data.data.name,
    isAuthenticated: data.data.isAuthenticated,
    isAdmin: data.data.isAdmin,
    isLoading: false,
    login,
  }
}