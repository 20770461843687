import { Row, Col, Nav } from "react-bootstrap";
import { Fragment } from "react";
import InvoicedPerDept from "./InvoicedPerDept";
import InvoicedPerDeptDelta from "./InvoicedPerDeptDelta";
import CountPerJobCode from "./CountPerJobCode";
import CountPerJobCodeDelta from "./CountPerJobCodeDelta";
import UnmappedSecureDepts from "./UnmappedSecureDepts";
import { Route, useParams, Switch } from "react-router";
import { Link } from "react-router-dom";
import { ArrowRight, Dot } from "react-bootstrap-icons";
import JobCodePerCustomer from "./JobCodePerCustomer";

const reports: ([string, string, boolean] | [string, string])[] = [
    ["invoicedperdept", "Invoiced Per Dept"],
    ["invoicedperdeptdelta", "Dept Comparison Prev Run"],
    ["countperjobcode", "Count Per Job Code"],
    ["countperjobcodedelta", "Job Code Comparison Prev Run"],
    ["jobcodepercustomer", "Job Code by Customer"],
    ["unmappedsecuredepts", "Unmapped Secure Depts"],
];

export default function InvoicingPage() {
    const { run: runID, report: reportID } = useParams<{ run: string; report?: string }>();
    return (
        <Row>
            <Col
                sm={4}
                lg={3}
                xl={2}
                className="bg-secondary"
                style={{ zIndex: 1, position: "fixed", top: 56, height: "calc(100vh - 56px)" }}>
                <div className="d-flex flex-column align-items-stretch mt-2 mb-2 w-100">
                    <h6>Reports</h6>
                    {reports.map(([id, name, disabled]) => (
                        <Link
                            key={id}
                            className={reportID === id ? "" : "text-muted"}
                            style={disabled ? {pointerEvents: "none", opacity: 0.6} : undefined}
                            to={disabled ? "#" : `/process/${runID}/reports/${id}`}>
                            {reportID === id ? <ArrowRight /> : <Dot />} {name}
                        </Link>
                    ))}
                </div>
            </Col>
            <Col
                sm={{ span: 8, offset: 4 }}
                lg={{ span: 9, offset: 3 }}
                xl={{ span: 10, offset: 2 }}>
                <Switch>
                    <Route path="/process/:run/reports/invoicedperdept">
                        <InvoicedPerDept />
                    </Route>
                    <Route path="/process/:run/reports/invoicedperdeptdelta">
                        <InvoicedPerDeptDelta />
                    </Route>
                    <Route path="/process/:run/reports/countperjobcode">
                        <CountPerJobCode />
                    </Route>
                    <Route path="/process/:run/reports/countperjobcodedelta">
                        <CountPerJobCodeDelta />
                    </Route>
                    <Route path="/process/:run/reports/jobcodepercustomer">
                        <JobCodePerCustomer />
                    </Route>
                    <Route path="/process/:run/reports/unmappedsecuredepts">
                        <UnmappedSecureDepts />
                    </Route>
                </Switch>
            </Col>
        </Row>
    );
}
