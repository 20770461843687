import {useParams} from "react-router-dom";
import ManagementDetailsEditor from "./Editors/Details";
import {Button, Tab, Tabs} from "react-bootstrap";
import useDataForm from "../../Hooks/useDataForm";
import useDataMutation from "../../Hooks/useDataMutation";
import ManagementInvoicingEditor from "./Editors/Invoicing";
import {useState, useEffect} from "react";
import ManagementContractsEditor from "./Editors/Contracts";
import { CascadingDetails, ContractsData } from "../../Types/Management";
import useAuth from "../../Hooks/useAuth";
import { Prompt } from 'react-router';


interface ManagementPageParams {
  client: string;
  site: string;
}

export default function ManagementSite() {
  const auth = useAuth();
  const params = useParams<ManagementPageParams>()
  const [site, siteForm, siteProperties] = useDataForm<CascadingDetails>(
      "/API/Clients/{client}/Sites/{site}",
      {
          client: params.client,
          site: params.site,
      }
  );
  const siteMutation = useDataMutation<CascadingDetails, CascadingDetails>("/API/Clients/{client}/Sites/{site}", {
    client: params.client,
    site: params.site
  });
  const [tab, setTab] = useState<string | null>("details");
  const [_, siteContractsForm, siteContractsProperties] = useDataForm<ContractsData>("/API/Clients/{client}/Sites/{site}/Contracts", {
    client: params.client,
    site: params.site
  }, {enabled: tab === "contracts"});
  const siteContractsMutation = useDataMutation<ContractsData, ContractsData>("/API/Clients/{client}/Sites/{site}/Contracts", {
    client: params.client,
    site: params.site
  }, {enabled: tab === "contracts"});
  const hasErrors = !siteProperties.isValid || !siteContractsProperties.isValid;
  const hasChanges = siteForm.formState.isDirty || siteContractsForm.formState.isDirty;
  return (
      <>
<Prompt
    when={hasChanges}
    message='You have unsaved changes, are you sure you want to leave?'
/>
    <div className="mt-2">
      <div className="d-flex flex-row align-items-center"><h4
        className="flex-grow-1">{site.data?.name ?? "Loading..."}</h4>
        {auth.isAdmin && <>          
          {hasErrors && <span className="text-danger mr-2">Please correct errors before saving</span>}
          <Button
            onClick={() => {
              siteForm.handleSubmit(() => {
                if (siteForm.formState.isDirty)
                siteMutation.mutate(siteForm.getValues());
              })();
              siteContractsForm.handleSubmit(() => {
                if (siteContractsForm.formState.isDirty)
                siteContractsMutation.mutate({
                    contracts: siteContractsForm
                      .getValues()
                      .contracts.filter((i) => !i.inherited),
                  });
              })();
            }}
            disabled={!hasChanges}
          >
            Save
          </Button>
          </>}
      </div>

      {!!site.data && <Tabs activeKey={tab} onSelect={t => setTab(t)} id="managementTabs">
          <Tab eventKey="details" title="Details">
              <ManagementDetailsEditor form={siteForm} data={site.data} type="site"/>
          </Tab>
          <Tab eventKey="invoicing" title="Invoicing">
              <ManagementInvoicingEditor form={siteForm} data={site.data} type="site"/>
          </Tab>
          <Tab eventKey="contracts" title="Contracts">
              <ManagementContractsEditor form={siteContractsForm}/>
          </Tab>
      </Tabs>}
    </div>
</>
  );
}
