import {  Form } from "react-bootstrap";
import { Config, VATRate } from "../../../Types/Settings";

interface VatRateSelectorProps {
    onChange: (vatCode: string) => void;
    value: string;
    config?: Config;
}

export function GetDefaultVatRate(config?: Config) : VATRate | null {
    const defaultVatRates = config?.vat.filter(r => r.isDefault);
    const defaultVatRate = (defaultVatRates?.length) ? defaultVatRates[0] : null;
    return defaultVatRate;
}

export function VatRateSelector(Props: VatRateSelectorProps) {
    
    const defaultVatRate = GetDefaultVatRate(Props.config);
    return <Form.Control
               as="select"
               value={Props.value}
               onChange={ev => Props.onChange(ev.target.value)}>
               {defaultVatRate &&
                   <option key="default" value={defaultVatRate.parameterKey}>
                       System Default ({defaultVatRate.parameterKey})
                   </option>}
               {Props.config?.vat.map(i => (
                   <option key={i.parameterKey} value={i.parameterKey}>
                       {i.parameterKey} - {i.description}
                   </option>
               ))}
           </Form.Control>;
}
