import {Form, Modal, Table} from "react-bootstrap";
import useData from "../../../Hooks/useData";
import {useMemo} from "react";
import useSearch from "../../../Hooks/useSearch";
import Fuse from "fuse.js";
import {ChevronRight} from "react-bootstrap-icons";
import {useParams} from "react-router-dom";
import {MinimalDept, MinimalSite} from "../../../Types/Management";

interface DeptSelectProps {
  onSelect(deptId: number): void;
}

export default function DeptSelect({onSelect}: DeptSelectProps) {
    const params = useParams<{client: string}>();
    const {data: sites} = useData<MinimalSite[]>("/API/Clients/{client}/Sites", {client: params.client});
    const { data, dataUpdatedAt } = useData<MinimalDept[]>("/API/Clients/{client}/Depts", {client: params.client});
    const [value, search, onValue] = useSearch(200);
    const list = useMemo(() => {
        if (!data) return [];
        if (!search) return data;
        const fuse = new Fuse(data, {
            keys: ["name", "siteName", "clientName"],
        });
        return fuse.search(search).map(i => i.item);
    }, [dataUpdatedAt, search]);
    return <>
        <Modal.Header closeButton>
            <Modal.Title>Select a Department</Modal.Title>
        </Modal.Header>
        <Form.Group controlId="jobSelectSearch" className="ml-2 mr-2">
            <Form.Label>Search</Form.Label>
            <Form.Control value={value} onChange={ev => onValue(ev.target.value)} placeholder="Search" />
        </Form.Group>
        <Table striped hover>
            <thead>
                <tr>
                    <td>Client</td>
                    <td>Site</td>
                    <td>Department</td>
                    <td style={{width: 20}} />
                </tr>
            </thead>
            <tbody>
                {list.filter(i=>!i.invoiceGroupId).map(i => <tr style={{cursor: "pointer"}} onClick={() => onSelect(i.deptId)}>
                    <td>{i.clientName}</td>
                    <td>{i.siteName}</td>
                    <td>{i.name}</td>
                    <td style={{width: 20}}><ChevronRight /></td>
                </tr>)}
            </tbody>
        </Table>
        {list.filter(i=>!i.invoiceGroupId).length === 0 && <h6>No Departments Available</h6>}
    </>
}