import { saveAs } from "file-saver";
import { Workbook } from "exceljs";
// @ts-expect-error
import rcolor from "rcolor";
import { useTheme } from "../../../Hooks/useTheme";
import { useMemo } from "react";
interface rColorOptions {
    hue?: number;
    saturation?: number;
    value?: number;
}
const rColor: ((options?: rColorOptions) => string) & { reSeed(): void } = rcolor;

export function useChartColours(n: number) {
    const light = useTheme();
    return useMemo(() => {
        const arr = new Array(n)
            .fill("")
            .map(() => rColor({ saturation: 0.5, value: light ? 0.7 : 1 }));
        return {
            backgroundColor: arr.map(i => i + "55"),
            borderColor: arr,
        };
    }, [n, light])
}

export async function saveWorkbook(fileName: string, workbook: Workbook) {
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
        new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        fileName
    );
}
