import {Form, Modal, Table} from "react-bootstrap";
import useData from "../../../Hooks/useData";
import {useMemo} from "react";
import useSearch from "../../../Hooks/useSearch";
import Fuse from "fuse.js";
import {ChevronRight} from "react-bootstrap-icons";
import {ContractsData} from "../../../Types/Management";

interface DeptJobSelectProps {
    deptId: number,
    onSelect(jobCode?: string, description?: string, defaultPrice?: number): void;
    existing: Set<string>;
}

export default function DeptJobSelect({deptId, existing, onSelect}: DeptJobSelectProps) {
    const { data, dataUpdatedAt } = useData<ContractsData>(`/API/DeptContracts/{deptId}`, { deptId });
    const [value, search, onValue] = useSearch(200);
    const list = useMemo(() => {
        if (!data?.contracts) return [];
        if (!search) return data.contracts;
        const fuse = new Fuse(data.contracts, {
            keys: ["jobCode", "description"],
        });
        return fuse.search(search).map(i => i.item);
    }, [dataUpdatedAt, search]);
    return <>
        <Modal.Header closeButton>
            <Modal.Title>Select a Job Code</Modal.Title>
        </Modal.Header>
        <Form.Group controlId="jobSelectSearch" className="ml-2 mr-2">
            <Form.Label>Search</Form.Label>
            <Form.Control value={value} onChange={ev => onValue(ev.target.value)} placeholder="Search by Job Code or Description" />
        </Form.Group>
        <Table striped hover>
            <thead>
                <tr>
                    <td>Job Code</td>
                    <td>Description</td>
                    <td>Price</td>
                    <td style={{width: 20}} />
                </tr>
            </thead>
            <tbody>
                {list.filter(i=>!existing.has(i.jobCode)).map(i => <tr key={i.jobCode} style={{cursor: "pointer"}} onClick={() => onSelect(i.jobCode, i.description || undefined, i.price || undefined)}>
                    <td>{i.jobCode}</td>
                    <td>{i.description}</td>
                    <td>£{i.price?.toFixed(2)}</td>
                    <td style={{width: 20}}><ChevronRight /></td>
                </tr>)}
            </tbody>
        </Table>
        {list.filter(i=>!existing.has(i.jobCode)).length === 0 && <h6>No Job Codes Available</h6>}
        <div style={{height:30}}></div>
    </>
}