import { Form } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import { EmailSettings } from "../../Types/Settings";
import useAuth from "../../Hooks/useAuth";

export interface EmailEditorProps {
    form: UseFormReturn<EmailSettings>;
}

export default function EmailEditor({ form }: EmailEditorProps) {
    const auth = useAuth();
    return <Form>
        <Form.Group controlId="emailSettingsFrom">
            <Form.Label>Email From Address</Form.Label>
            <Form.Control readOnly={!auth.isAdmin} {...form.register("emailFromAddress")} type="text" />
        </Form.Group>
        <Form.Group controlId="emailSettingsCc">
            <Form.Label>Email CC Address</Form.Label>
            <Form.Control readOnly={!auth.isAdmin} {...form.register("emailCcAddress")} type="text" />
        </Form.Group>
        <Form.Group controlId="emailSettingsAddress">
            <Form.Label>Email BCC Address</Form.Label>
            <Form.Control readOnly={!auth.isAdmin} {...form.register("emailBccAddress")} type="text" />
        </Form.Group>
        <Form.Group controlId="emailSettingsSubject">
            <Form.Label>Email Subject</Form.Label>
            <Form.Control readOnly={!auth.isAdmin} {...form.register("emailSubject")} type="text" />
            <Form.Text>Available Fields: {["InvoiceNumber", "Month", "Name", "Date"].map(i => `{${i}}`).join(', ')}</Form.Text>
        </Form.Group>
        <Form.Group controlId="emailSettingsMessage">
            <Form.Label>Email Content</Form.Label>
            <Form.Control readOnly={!auth.isAdmin} {...form.register("emailContent")} className="text-monospace" type="text" as="textarea" rows={10} />
            <Form.Text>Available Fields: {["InvoiceNumber", "Month", "Name", "Date"].map(i => `{${i}}`).join(', ')}</Form.Text>
        </Form.Group>
        <Form.Group controlId="emailSettingsTestRecipient">
            <Form.Label>Test Email Recipient</Form.Label>
            <Form.Control readOnly={!auth.isAdmin} {...form.register("emailTestSendAllEmailsRecipient")} type="text" />
            <Form.Text>Send all emails to test recipient</Form.Text>
        </Form.Group>
    </Form>
}