import {Button, Form, Table} from "react-bootstrap";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import { SettingsData } from "../../Types/Settings";
import useAuth from "../../Hooks/useAuth";
import ControlledCheckbox from "../../Components/ControlledCheckbox";
import { FileSpreadsheet } from "react-bootstrap-icons";
import { useState } from "react";

export interface VATEditorProps {
  form: UseFormReturn<SettingsData>;
}

export default function VATEditor({ form }: VATEditorProps) {
  const [_, setUpdate] = useState(0);
  const auth = useAuth();
  const {fields: vatFields, append: vatAppend} = useFieldArray({control: form.control, name: "vat"});
  return (
      <>
          <Table striped bordered size="sm" hover className="m-0">
              <thead>
                  <tr>
                      <th>VAT Code</th>
                      <th>Description</th>
                      <th>VAT Amount (%)</th>
                      <th>Default?</th>
                  </tr>
              </thead>
              <tbody>
                  {vatFields.map((field, index) => {
                      // Workaround for "<Value> as <Type>" syntax not working
                      const jobString: any = (i: string) => `vat.${index}.${i}`;

                      // Keep Data
                      if (!field.newRate) form.register(jobString("parameterKey"));
                      form.register(jobString("isDefault"));
                      return (
                          <tr key={index.toString()}>
                              {field.newRate ? (
                                  <td className="p-0">
                                      <Form.Control
                                          readOnly={!auth.isAdmin}
                                          placeholder="VAT Code (Required)"
                                          defaultValue={field.parameterKey || ""}
                                          {...form.register(jobString("parameterKey"), {
                                              required: true,
                                          })}
                                      />
                                  </td>
                              ) : (
                                  <td>{field.parameterKey}</td>
                              )}
                              <td className="p-0">
                                  <Form.Control
                                      readOnly={!auth.isAdmin}
                                      defaultValue={field.description || ""}
                                      {...form.register(jobString("description"))}
                                  />
                              </td>
                              <td className="p-0">
                                  <Form.Control
                                      type="number"
                                      readOnly={!auth.isAdmin}
                                      defaultValue={field.valueNumeric ?? ""}
                                      {...form.register(jobString("valueNumeric"), {
                                          valueAsNumber: true,
                                      })}
                                  />
                              </td>
                          <td>
                                  <Form.Check
                                      id={`job-check-${field.id}`}
                                      custom
                                      checked={!!form.watch(jobString(`isDefault`))}
                                      onChange={() => {
                                          vatFields.forEach((i, n) => {
                                              const s: any = `vat.${n}.isDefault`;
                                              form.setValue(s, i.id === field.id, {shouldDirty: true});
                                          });
                                      }}
                                      disabled={!auth.isAdmin}
                                  />
                              </td>
                          </tr>
                      );
                  })}
              </tbody>
          </Table>
          {auth.isAdmin && (
              <Button
                  className="m-2"
                  onClick={() => {
                      vatAppend({
                          parameterKey: "",
                          description: "",
                          isDefault: false,
                          valueNumeric: 0,
                          newRate: true,
                      });
                  }}>
                  Add VAT Rate
              </Button>
          )}
      </>
  );
}