import {Modal} from "react-bootstrap";
import useData from "../../../Hooks/useData";

interface SageExportProps {
  runId: number;
}

export default function SageExport({runId}: SageExportProps) {
  const sageExportDetails = useData<{ validationErrors: string[] | undefined, previousExports: string[] | undefined }>("/API/Exports/Runs/{run}/SageStatus", {
    run: runId,
  });

  if (!sageExportDetails.data) {
    return <h6>Loading...</h6>;
  }
  
  const {validationErrors, previousExports} = sageExportDetails.data;
  
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Sage Export</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {validationErrors && validationErrors.length ?
        <>
            <h6>Validation Errors</h6>
            <ul>
              {validationErrors.map((validationError: string) => <li className="text-danger">{validationError}</li>)}
            </ul>
        </> : <></>
        }

        <h6>Previous Exports</h6>
        {previousExports && previousExports.length ?
        <>
            <ul>
              {previousExports.map((previousExports: string) => <li>{previousExports}</li>)}
            </ul>
        </> :
          <><span className="text-info">This run has not yet been exported</span></>
        }
        </Modal.Body>
      <Modal.Footer>
        { validationErrors && validationErrors.length ? <span className="text-warning">Please fix the errors above in order to export</span> : 
        <a className='btn btn-primary' href={`/API/Exports/Runs/${runId}/Sage`}>{previousExports && previousExports.length ? <>Download again</> : <>Download Sage Export</>}</a>}
      </Modal.Footer>

      </>
          );
        }