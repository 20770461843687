const GBPFormatter = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" });
const NUM_2Formatter = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2 });
const NUMFormatter = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 0 });

export function formatNumber(number: number, format: "GBP" | "NUM.2" | "NUM"): string {
    switch (format) {
      case "GBP":
        return GBPFormatter.format(number);
      case "NUM.2":
        return NUM_2Formatter.format(number);
      case "NUM":
        return NUMFormatter.format(number);
    }
}