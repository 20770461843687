import {Contract} from "../../../Types/Management";
import {Button, Form, Modal, Table} from "react-bootstrap";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import JobSelect from "./JobSelect";
import {Fragment, useState} from "react";
import useAuth from "../../../Hooks/useAuth";
import { DevTool } from "@hookform/devtools"

export interface ManagementContractsEditorProps {
  form: UseFormReturn<{ contracts: Contract[] }>
}

export default function ManagementContractsEditor({form}: ManagementContractsEditorProps) {
  const auth = useAuth();
  const readOnly = !auth.isAdmin;
  const {fields, append, remove} = useFieldArray({control: form.control, name: "contracts"});
  const [showModal, setShowModal] = useState(false);

  function getOverriding(field: any, key: any) {
    return fields
        .filter(i => i.inherited && i.jobCode === field.jobCode)
        .map((i: any) => `Overriding value: ${i[key]} from ${i.inherited}`)
        .join("\n");
  }
  return <div>
    <Table striped bordered size="sm" hover className="mb-0">
      <thead>
      <tr className="bg-secondary">
        <th>Job Code</th>
        <th>Description</th>
        <th>Price</th>
        <th>Recurring Qty</th>
        {!readOnly && <th>Action</th>}
      </tr>
      </thead>
      <tbody>
      {fields.map((field, index) => {
        if (field.inherited) return <Fragment key={field.id}/>;
        // Workaround for "<Value> as <Type>" syntax not working
        const contractString: any = (i: string) => `contracts.${index}.${i}`;

        // Keep Data
        form.register(contractString("jobCode"))
        form.register(contractString("description"))
        form.register(contractString("inherited"))
        return (
            <tr key={field.id}>
                <td>{field.jobCode}</td>
                <td>{field.description}</td>
                <td className="p-0">
                    <Form.Control
                        title={getOverriding(field, "price")}
                        readOnly={readOnly}
                        defaultValue={field.price || 0}
                        {...form.register(contractString("price", { valueAsNumber: true }))}
                    />
                </td>
                <td className="p-0">
                    <Form.Control
                        title={getOverriding(field, "recurringQty")}
                        readOnly={readOnly}
                        defaultValue={field.recurringQty || 0}
                        {...form.register(contractString("recurringQty", { valueAsNumber: true }))}
                    />
                </td>
                {!readOnly && (
                    <td className="p-0">
                        <Button onClick={() => remove(index)} variant="outline-danger border-0">
                            Discard
                        </Button>
                    </td>
                )}
            </tr>
        );
      })}
      {!!fields.find((i, n) => i.inherited === "Site" && !fields.find(j => j.jobCode === i.jobCode && !j.inherited)) &&
      <tr>
          <td className="bg-secondary" colSpan={5}>Site agreed pricing</td>
      </tr>}
      {fields.map((field, index) => {
        if (field.inherited !== "Site") return <Fragment key={`s_${field.id}`}/>;
        // Workaround for "<Value> as <Type>" syntax not working
        const contractString: any = (i: string) => `contracts.${index}.${i}`;

        // Keep Data
        form.register(contractString("jobCode"))
        form.register(contractString("description"))
        form.register(contractString("inherited"))
        form.register(contractString("price"))
        form.register(contractString("recurringQty"))
        if (fields.find(i => i.jobCode === field.jobCode && !i.inherited)) return <Fragment key={`s_${field.id}`}/>;
        return (
          <tr key={`s_${field.id}`} style={{opacity: 0.6}}>
            <td>{field.jobCode}</td>
            <td>{field.description}</td>
            <td>{field.price || 0}</td>
            <td>{field.recurringQty || 0}</td>
            {!readOnly && <td className="p-0"/>}
          </tr>
        )
      })}
      {!!fields.find((i, n) => i.inherited === "Client" && !fields.find(j => j.jobCode === i.jobCode && (!j.inherited || j.inherited === "Site"))) &&
      <tr>
          <td className="bg-secondary" colSpan={5}>Client agreed pricing</td>
      </tr>}
      {fields.map((field, index) => {
        if (field.inherited !== "Client") return <Fragment key={`c_${field.id}`}/>;
        // Workaround for "<Value> as <Type>" syntax not working
        const contractString: any = (i: string) => `contracts.${index}.${i}`;

        // Keep Data
        form.register(contractString("jobCode"))
        form.register(contractString("description"))
        form.register(contractString("inherited"))
        form.register(contractString("price"))
        form.register(contractString("recurringQty"))
        if (fields.find(i => i.jobCode === field.jobCode && (!i.inherited || i.inherited === "Site"))) return <Fragment
          key={`c_${field.id}`}/>;
        return (
          <tr key={`c_${field.id}`} style={{opacity: 0.6}}>
            <td>{field.jobCode}</td>
            <td>{field.description}</td>
            <td>{field.price || 0}</td>
            <td>{field.recurringQty || 0}</td>
            {!readOnly && <td className="p-0"/>}
          </tr>
        )
      })}
  </tbody>
</Table><DevTool placement="bottom-right" control={form.control}/>
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <JobSelect onSelect={(jobCode, description, defaultPrice) => {
        if (jobCode) append({
          jobCode: jobCode,
          description: description,
          price: defaultPrice,
          recurringQty: 0,
          inherited: "",
        });
    }} existing={new Set(fields.filter(i => !i.inherited).map(i => i.jobCode))}/>
    </Modal>
    {auth.isAdmin && <Button className="m-2" onClick={() => setShowModal(true)}>Add Contract</Button>}
  </div>
}