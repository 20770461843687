import { Col, Form} from "react-bootstrap";
import {UseFormReturn} from "react-hook-form";
import ControlledCheckbox from "../../../Components/ControlledCheckbox";
import {InvoiceGroup} from "../../../Types/Management";
import useAuth from "../../../Hooks/useAuth";

export interface ManagementGroupEditorProps {
    form: UseFormReturn<InvoiceGroup>;
}

export default function ManagementGroupEditor({form}: ManagementGroupEditorProps) {
    const auth = useAuth();
    const g = {readOnly: !auth.isAdmin};
    return <Form className="p-2">
        <Form.Row>
            <Col md={6}>
                <legend>Details</legend>
                <Form.Group controlId="managementSettingsName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control {...g} {...form.register("invoiceGroupName", {required:true})} type="text" isInvalid={!!form.formState.errors?.invoiceGroupName} />
                </Form.Group>
                <Form.Group controlId="managementSettingsAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control {...g} {...form.register("invoiceAddress")} as="textarea" rows={4} type="text" />
                </Form.Group>
                <Form.Group controlId="managementSettingsPostcode">
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control {...g} {...form.register("invoicePostcode")} type="text" />
                </Form.Group>
                <Form.Row>
                    <Col md={6}>
                        <Form.Group controlId="managementSettingsEmailCheckbox">
                            <ControlledCheckbox disabled={g.readOnly} label="Email Invoices?" control={form.control} name="emailInvoices" />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="managementSettingsPrintCheckbox">
                            <ControlledCheckbox disabled={g.readOnly} label="Print Invoices?" control={form.control} name="printInvoices" />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Col>
            <Col md={6}>
                <legend>Contact</legend>
                <Form.Group controlId="managementSettingsContactName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control {...g} {...form.register("invoiceContact")} type="text" />
                </Form.Group>
                <Form.Group controlId="managementSettingsPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control {...g} {...form.register("invoiceTelephone")} type="text" />
                </Form.Group>
                <Form.Group controlId="managementSettingsEmail">
                    <Form.Label>Email Addresses</Form.Label>
                    <Form.Control {...g} {...form.register("invoiceEmailAddresses")} type="email" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <legend>VAT</legend>
                <Form.Group controlId="managementSettingsVATNumber">
                    <Form.Label>VAT Number</Form.Label>
                    <Form.Control {...g} {...form.register("vatNumber")} type="text" />
                </Form.Group>
                <Form.Group controlId="managementSettingsVATCode">
                    <Form.Label>VAT Code</Form.Label>
                    <Form.Control {...g} {...form.register("vatCode")} type="text" />
                </Form.Group>
            </Col>
            <Col md={6}>
                <legend>Other</legend>
                <Form.Group controlId="managementSettingsRegistration">
                    <Form.Label>Registration</Form.Label>
                    <Form.Control {...g} {...form.register("registration")} type="text" />
                </Form.Group>
                <Form.Group controlId="managementSettingsAccountsCode">
                    <Form.Label>Accounts Code</Form.Label>
                    <Form.Control {...g} {...form.register("accountsCode")} type="text" />
                </Form.Group>
                <Form.Group controlId="managementSettingsPaymentDays">
                    <Form.Label>Payment Days</Form.Label>
                    <Form.Control {...g} {...form.register("paymentDays")} type="text" />
                </Form.Group>
            </Col>
        </Form.Row>
    </Form>
}