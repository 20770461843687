import {useState} from "react";
import {Button, Table} from "react-bootstrap";
import useData from "../../Hooks/useData";
import useAuth from "../../Hooks/useAuth";
import { User } from "../../Types/Users";

export default function UsersPage() {
  const auth = useAuth();
  const users = useData<User[]>("/API/Users", {}, {retry: false});
  if (users.isError) {
    location.href = `/API/Auth/LegacyLogout?redirectTo=${encodeURIComponent(`/API/Auth/Login?redirectTo=/users`)}`;
    return <>Redirecting to Microsoft...</>
  }
  if (!users.data) {
    return <>Loading...</>;
  }
  return (
    <div>
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Users</h1>
        {/* WARNING: This link may break */}
        {auth.isAdmin && <Button target="_blank" href="https://aad.portal.azure.com/#blade/Microsoft_AAD_IAM/UsersManagementMenuBlade/MsGraphUsers">Manage on AAD</Button>}
      </div>
      <Table size="sm" bordered>
        <thead>
        <tr className="bg-secondary">
          <th>Full Name</th>
          <th>Email</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        {users.data.map(({key: id, value: user}) => (<tr key={id}>
          <th>{user.name}</th>
          <td>{user.email}</td>
          <td>{user.type}</td>
        </tr>))}
        </tbody>
      </Table>
    </div>
  );
}
