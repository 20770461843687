import {Col, Modal, Nav, Row} from "react-bootstrap";
import {Link, Route, Switch, useParams, useHistory} from "react-router-dom";
import ManagementClient from "./Client";
import ManagementDept from "./Dept";
import ManagementGroup from "./Group";
import ManagementSite from "./Site";
import useData from "../../Hooks/useData";
import {ArrowLeft, ArrowRight, Dot, Plus} from "react-bootstrap-icons";
import Create from "./Editors/Create";
import {Fragment, useState} from "react";
import useDataMutation from "../../Hooks/useDataMutation";
import {MinimalClient, MinimalDept, MinimalGroup, MinimalSite} from "../../Types/Management";
import useAuth from "../../Hooks/useAuth";

interface ManagementPageParams {
  client?: string;
  site?: string;
  dept?: string;
  group?: string;
}

export default function ManagementPage() {
  const auth = useAuth();
  const history = useHistory();
  const clients = useData<MinimalClient[]>("/API/Clients");
  const globalGroups = useData<MinimalGroup[]>("/API/Clients/0/Groups");
  const params = useParams<ManagementPageParams>();
  const sites = useData<MinimalSite[]>("/API/Clients/{client}/Sites", {client: params.client}, {enabled: params.client !== undefined});
  const groups = useData<MinimalGroup[]>("/API/Clients/{client}/Groups", {client: params.client}, {enabled: params.client !== undefined});
  const depts = useData<MinimalDept[]>("/API/Clients/{client}/Sites/{site}/Depts", {
    client: params.client,
    site: params.site
  }, {enabled: params.site !== undefined && params.client !== undefined});

  // Creation Mutations
  const createClientMutation = useDataMutation<{ success: boolean, newClientId: number }, { name: string }>("/API/Clients/Create", {});
  const createSiteMutation = useDataMutation<{ success: boolean, newSiteId: number }, { name: string, clientId: number }>("/API/Clients/{client}/Sites/Create", {client: params.client});
  const createGroupMutation = useDataMutation<{ success: boolean, newGroupId: number }, { invoiceGroupName: string, clientId: number }>("/API/Clients/{client}/Groups/Create", {client: params.client});
  const createDeptMutation = useDataMutation<{ success: boolean, newDeptId: number }, { name: string, siteId: number }>("/API/Clients/{client}/Sites/{site}/Depts/Create", {
    client: params.client,
    site: params.site
  });
  const [modal, setModal] = useState<string | null>(null);
  return (
    <Row>
      <Col sm={4} lg={3} xl={2} className="bg-secondary" style={{zIndex: 1, position: "fixed", top: 56, height: "calc(100vh - 56px)", overflowY:"auto"}}>
        <Nav
          variant="pills"
          defaultActiveKey="/home"
          className="flex-column align-items-stretch mt-2 mb-2 w-100">
          <Switch>
            <Route path="/management/:client">
              <Link to="/management" className="text-white">
                <ArrowLeft/> Back to Clients
              </Link>
                {params.client !== "0" && <>
              <Link
                key={params.client}
                className={(!params.site && !params.group) ? "" : "text-muted"}
                to={`/management/${params.client}`}>
                {(!params.site && !params.group) ? <ArrowRight/> :
                  <Dot/>} {clients.data?.find(client => client.clientId === Number(params.client))?.name}
              </Link>
                
              <h5 className="mt-2">Sites</h5>
              <>
                {sites.data?.map(site => { 
                  const isCurrentSite = Number(params.site) === site.siteId;

                  return(
                  <Fragment key={site.siteId}>
                    <Link
                      key={site.siteId}
                      className={isCurrentSite ? !params.dept ? "" : "text-white" : "text-muted"}
                      to={`/management/${params.client}/${site.siteId}`}>
                      {(Number(params.site) === site.siteId && !params.dept) ? <ArrowRight/> : <Dot/>} {site.name}
                    </Link>

                    {isCurrentSite && <>
                      {depts.data?.map(dept => (
                        <Link
                          key={dept.deptId}
                          className={(Number(params.dept) === dept.deptId) ? "ml-3" : "ml-3 text-muted"}
                          to={`/management/${params.client}/${params.site}/${dept.deptId}`}>
                          {(Number(params.dept) === dept.deptId) ? <ArrowRight/> : <Dot/>} {dept.name}
                        </Link>
                      )) ?? <div>Loading...</div>}
                      {auth.isAdmin && <a href="#" className="ml-3 font-italic" onClick={() => setModal("dept")}>
                          <Plus/> Create New Department
                      </a>}
                        <Modal show={modal === "dept"} onHide={() => setModal(null)}>
                            <Create onHide={() => setModal(null)} onCreate={(value) => {
                              createDeptMutation.mutateAsync({
                                name: value,
                                siteId: Number(params.site),
                              }).then((data)=>{
                                setModal(null);
                                history.push(`/management/${params.client}/${params.site}/${data.newDeptId}`);
                              });
                            }} type="Department"/>
                        </Modal>
                    </>}
                  </Fragment>
                )}) ?? <div>Loading...</div>}
              </>

              {auth.isAdmin && <a href="#" className="font-italic" onClick={() => setModal("site")}>
                  <Plus/> Create New Site
              </a>}
              <Modal show={modal === "site"} onHide={() => setModal(null)}>
                <Create onHide={() => setModal(null)} onCreate={(value) => {
                  createSiteMutation.mutateAsync({
                    name: value,
                    clientId: Number(params.client),
                  }).then((data)=>{
                    setModal(null);
                    history.push(`/management/${params.client}/${data.newSiteId}`);
                  });
                  
                }} type="Site"/>
              </Modal>
                              
              <h6 className="mt-1">Groups</h6>
                              </>}
                {params.client === "0" && <h6 className="mt-1">Multi-Client Groups</h6>}
              {groups.data?.map(group => (
                <Link
                  key={group.invoiceGroupId}
                  className={Number(params.group) === group.invoiceGroupId ? "" : "text-muted"}
                  to={`/management/${params.client}/group/${group.invoiceGroupId}`}>
                  {(Number(params.group) === group.invoiceGroupId) ? <ArrowRight/> : <Dot/>} {group.invoiceGroupName}
                </Link>
              )) ?? <div>Loading...</div>}
              {auth.isAdmin && <a href="#" className="font-italic" onClick={() => setModal("group")}>
                  <Plus/> Create New Invoicing Group
              </a>}
              <Modal show={modal === "group"} onHide={() => setModal(null)}>
                <Create onHide={() => setModal(null)} onCreate={(value) => {
                  createGroupMutation.mutateAsync({
                    invoiceGroupName: value,
                    clientId: Number(params.client),
                  }).then((data)=>{
                    setModal(null);
                    history.push(`/management/${params.client}/group/${data.newGroupId}`);
                  });
                }} type="Invoicing Group"/>
              </Modal>
            </Route>
            <Route path="/management">
              <h6>Clients</h6>
              {clients.data?.map(client => (
                <Link
                  key={client.clientId}
                  className={Number(params.client) === client.clientId ? "" : "text-muted"}
                  to={`/management/${client.clientId}`}>
                  <Dot/> {client.name}
                </Link>
              )) ?? <div>Loading...</div>}
              {auth.isAdmin && <a href="#" className="font-italic" onClick={() => setModal("client")}>
                  <Plus/> Create New Client
              </a>}
              <Modal show={modal === "client"} onHide={() => setModal(null)}>
                <Create onHide={() => setModal(null)} onCreate={(value) => {
                  createClientMutation.mutateAsync({
                    name: value
                  }).then((data) => {
                    setModal(null);
                    history.push(`/management/${data.newClientId}`);
                  });
                  
                }} type="Client"/>
              </Modal>
              <h6 className="mt-1">Multi-Client Groups</h6>
              {globalGroups.data?.map(group => (
                <Link
                  key={group.invoiceGroupId}
                  className={Number(params.group) === group.invoiceGroupId ? "" : "text-muted"}
                  to={`/management/0/group/${group.invoiceGroupId}`}>
                  {(Number(params.group) === group.invoiceGroupId) ? <ArrowRight/> : <Dot/>} {group.invoiceGroupName}
                </Link>
              )) ?? <div>Loading...</div>}
              {auth.isAdmin && <a href="#" className="font-italic" onClick={() => setModal("group")}>
                  <Plus/> Create New Invoicing Group
              </a>}
              <Modal show={modal === "group"} onHide={() => setModal(null)}>
                  <Create onHide={() => setModal(null)} onCreate={(value) => {
                  createGroupMutation.mutateAsync({
                    invoiceGroupName: value,
                    clientId: 0,
                  }).then((data)=>{
                    setModal(null);
                    history.push(`/management/0/group/${data.newGroupId}`);
                  });
                  setModal(null);
                }} type="Invoicing Group"/>
              </Modal>
            </Route>
          </Switch>
        </Nav>
      </Col>
      <Col sm={{span: 8, offset: 4}} lg={{span: 9, offset: 3}} xl={{span: 10, offset: 2}}>
        <Switch>
          <Route path="/management/:client/group/:group"><ManagementGroup key="group"/></Route>
          <Route path="/management/:client/:site/:dept"><ManagementDept  key="dept"/></Route>
          <Route path="/management/:client/:site"><ManagementSite key="site"/></Route>
          <Route path="/management/:client"><ManagementClient key="client"/></Route>
        </Switch>
      </Col>
    </Row>
  );
}
